import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  DatePicker,
  TreeSelect,
  Upload,
  Image,
  Button,
  TimePicker,
  Tag,
  Row,
  Col,
  message,
  notification,
  Spin,
} from 'antd';
import {
  UploadOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import imageDefault from '../../.././../assets/img/png/camara.ico';
import { getMenuStucture } from '../../../../api/menu';
import { addPromoToCompany } from '../../../../api/promo';
import { getAccessTokenApi } from '../../../../api/auth';
import Resizer from 'react-image-file-resizer';

const AddPromoForm = ({ setIsModalVisible, setReloadPromos }) => {
  const [item, setItem] = useState(null);
  const [image, setImage] = useState({
    imageUrl: '',
  });
  const { imageUrl } = image;

  const [filesList, setFileList] = useState({});
  // const [showTime, setShowTime] = useState();
  const [promoType, setPromoType] = useState(0);
  const [showPeriod, setShowPeriod] = useState('range-dates');
  const [loadingForm, setLoadingForm] = useState(false);
  const [codeChecked, setCodeChecked] = useState(false);
  const [treeMenu, setTreeMenu] = useState([]);
  const token = getAccessTokenApi();

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const { SHOW_PARENT } = TreeSelect;
  const format = 'h:mm a';

  // console.log(promoType);
  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  const layoutPromoType = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const layoutSwitch = {
    labelCol: { span: 18 },
    wrapperCol: { span: 6 },
  };

  // const layoutCoupon = {
  //     labelCol: { span: 12 },
  //     wrapperCol: { span: 12 },
  // };

  const layoutDatePicker = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };

  const handlePickItem = (value) => {
    // console.log('onChange ', value);
    setItem(value);
  };

  useEffect(() => {
    let dataTree = [];
    getMenuStucture(token).then((menu) => {
      if (menu === undefined) {
        notification['error']({
          message:
            'Ocurrio un error al cargar el menu intentelo de nuevo mas tarde..',
        });

        setIsModalVisible(false);

        return;
      }

      menu.forEach((group) => {
        let groupElement = {
          title: group.name,
          value: `group-${group.groupId}`,
          key: `group-${group.groupId}`,
          children: [],
        };

        group.categories.forEach((category) => {
          let categoryElement = {
            title: category.name,
            value: `category-${category.categoryId}`,
            key: `category-${category.categoryId}`,
            children: [],
          };
          groupElement.children.push(categoryElement);

          category.items.forEach((item) => {
            let itemElement = {
              title: item.name,
              value: `item-${item.itemId}`,
              key: `item-${item.itemId}`,
            };

            categoryElement.children.push(itemElement);
          });
        });

        dataTree.push(groupElement);
      });
      setTreeMenu(dataTree);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const itemProps = {
    treeData: treeMenu,
    value: item,
    onChange: handlePickItem,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Por favor selecciona un elemento',
    style: {
      width: '100%',
    },
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir archivos JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La imegen debe ser menos de 2MB!');
    }
    //   return isJpgOrPng && isLt2M;
    return false;
  }

  const handleChange = (info) => {
    let fileList = [...info.fileList];

    if (Array.isArray(fileList) && fileList.length) {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      let file = fileList[0];

      getBase64(file.originFileObj, (imageUrl) =>
        setImage({
          imageUrl: imageUrl,
        })
      );
    } else {
      setImage({
        imageUrl: null,
      });
    }
    setFileList({ fileList });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList.slice(-1);
  };

  // const changeCheckTime = (e, day) => {
  //     setShowTime({
  //         ...showTime,
  //         [day]: e.target.checked,
  //     });
  // };

  const handleTypeShow = (value) => {
    // console.log(value);
    setShowPeriod(value);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        85,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const addPromo = async (values) => {
    setLoadingForm(true);
    if (values.Code) {
      values.Code = values.Code.replace(/\s/g, '');
    }

    if (values.dateLimitRange) {
      values['StartDate'] = values.dateLimitRange[0].unix();
      values['EndDate'] = values.dateLimitRange[1].unix();
    }

    if (values.timeDays) {
      values['TimeStart'] = values.timeDays[0].unix();
      values['TimeEnd'] = values.timeDays[1].unix();
    }

    // console.log('Enviar a la api', values);

    //tiene imagen
    if (values.upload !== undefined) {
      if (values.upload.length > 0) {
        let image = values.upload[0];

        const isJpgOrPng =
          image.type === 'image/jpeg' ||
          image.type === 'image/png' ||
          image.type === 'image/jpg';

        if (!isJpgOrPng) {
          notification['error']({
            message: '¡Solo puedes subir archivos JPG/PNG/JPEG!',
          });
          setLoadingForm(false);

          return;
        }

        const isLt2M = image.size / 1000 < 250;
        let imageResize = '';
        if (!isLt2M) {
          notification['warning']({
            message:
              'La imagen supera los 250kb, por lo tanto será reducida de tamaño.',
            placement: 'bottomRight',
            duration: 3,
          });
          // message.warning('¡La imagen supera los 250kb, sera reducida de tamaño.');
          imageResize = await resizeFile(image.originFileObj);
        }

        let posData64;

        let posType = image.type.indexOf('/') + 1;
        if (isLt2M) {
          posData64 = image.thumbUrl.indexOf(',') + 1;
          values['ImageData'] = image.thumbUrl.substr(posData64);
        } else {
          posData64 = imageResize.indexOf(',') + 1;
          values['ImageData'] = imageResize.substr(posData64);
        }

        values['Extension'] = image.type.substr(posType);
      }
    }
    delete values.dateLimitRange;
    delete values.upload;
    // console.log(values, 'values');
    const result = await addPromoToCompany(token, values);
    // console.log(result, 'result');
    if (result === undefined) {
      let messageError =
        'Ocurrio un problema al crear una nueva promoción, Intentelo mas tarde';

      notification['error']({
        message: messageError,
      });

      setLoadingForm(false);
      return;
    }

    if (result.statusCode === 200) {
      form.resetFields();
      setIsModalVisible(false);
      setReloadPromos(true);
      notification['success']({
        message: 'Promoción agregado exitosamente',
      });
    } else {
      let messageError =
        'Ocurrio un problema al crear una nueva promoción, Intentelo otra vez';

      if (result.statusCode === 400) {
        messageError = result.description;
      }
      if (result.statusCode === 404) {
        messageError = result.description;
      }

      if (result.statusCode === 409) {
        messageError = result.description;
      }

      notification['error']({
        message: messageError,
      });

      setLoadingForm(false);
    }
  };

  const options = [
    { label: 'Lunes', value: 1 },
    { label: 'Martes', value: 2 },
    { label: 'Miércoles', value: 3 },
    { label: 'Jueves', value: 4 },
    { label: 'Viernes', value: 5 },
    { label: 'Sabado', value: 6 },
    { label: 'Domingo', value: 7 },
  ];

  function tagRender(props) {
    const { label, value, closable, onClose } = props;

    let color = '';

    switch (value) {
      case 1:
        color = 'gold';
        break;
      case 2:
        color = 'magenta';
        break;
      case 3:
        color = 'green';
        break;
      case 4:
        color = 'volcano';
        break;
      case 5:
        color = 'blue';
        break;
      case 6:
        color = 'red';
        break;
      case 7:
        color = 'purple';
        break;
      default:
        color = 'cyan';
        break;
    }

    return (
      <Tag
        color={color}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form
        {...layout}
        name="add-promo"
        form={form}
        onFinish={addPromo}
        initialValues={{ DurationType: 'range-dates' }}
      >
        <Form.Item
          {...layoutPromoType}
          label="Tipo de Promoción"
          name="Type"
          rules={[
            {
              required: true,
              message: 'Por favor seleccione un tipo.',
            },
          ]}
          tooltip="Promoción a Configurar."
        >
          <Select
            placeholder="Seleccione una promoción"
            name="rol"
            onChange={(value) => setPromoType(value)}
          >
            <Option value={1}>2x1</Option>
            <Option value={2}>3x2</Option>
            <Option value={3}>{'Descuento en Precios a Producto ($)'}</Option>
            <Option value={4}>
              {'Descuento en Porcentajes a Producto(%)'}
            </Option>
            <Option value={5}>{'Descuento en Precios a la Orden ($)'}</Option>
            <Option value={6}>
              {'Descuento en Porcentaje a la Orden (%)'}
            </Option>
            <Option value={7}>{'Envío a domicilio gratis'}</Option>
          </Select>
        </Form.Item>
        {(promoType === 1 || promoType === 2) && (
          <Form.Item
            label="Código requerido"
            tooltip="Al no definir código el descuento se mostrará y aplicará automáticamente en los productos"
          >
            <Switch
              checked={codeChecked}
              onChange={(check) => setCodeChecked(check)}
            />
          </Form.Item>
        )}

        {((promoType !== 3 &&
          promoType !== 4 &&
          promoType !== 1 &&
          promoType !== 2) ||
          (promoType === 1 && codeChecked) ||
          (promoType === 2 && codeChecked)) && (
          <Form.Item
            label="Código de la promoción"
            name="Code"
            rules={[
              {
                whitespace: true,
                required: true,
                message: 'Por favor ingrese un código.',
              },
            ]}
            tooltip="Código para hacer válida la promoción."
          >
            <Input placeholder="Código" />
          </Form.Item>
        )}

        <Form.Item
          label="Nombre de la promoción"
          name="Name"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un nombre.',
            },
          ]}
          tooltip="Nombre de la promoción."
        >
          <Input placeholder="Nombre" />
        </Form.Item>

        {promoType !== 1 &&
          promoType !== 2 &&
          promoType !== 3 &&
          promoType !== 4 && (
            <Form.Item
              label="Consumo mínimo"
              name="Minimum"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingrese una cantidad.',
                },
              ]}
              tooltip="Monto mínimo de la compra para hacer válida la promoción."
            >
              <InputNumber
                placeholder="Consumo mínimo"
                style={{ width: '100%' }}
                // defaultValue={0}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
          )}

        {promoType !== 1 && promoType !== 2 && promoType !== 7 && (
          <Row>
            <Col xs={24} md={12}>
              <Form.Item
                label="Porcentaje"
                name="DiscountPercent"
                dependencies={['Type']}
                rules={[
                  ({ getFieldValue }) => ({
                    required:
                      getFieldValue('Type') === 4
                        ? true
                        : getFieldValue('Type') === 6
                        ? true
                        : false,
                    message: 'Por favor ingrese una cantidad.',
                  }),
                ]}
                tooltip="Porcentaje de descuento."
              >
                <InputNumber
                  style={{ width: '100%' }}
                  // defaultValue={0}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace('%', '')}
                  disabled={
                    promoType === 3 ? true : promoType === 5 ? true : false
                  }
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Precio"
                name="DiscountPrice"
                dependencies={['Type']}
                rules={[
                  ({ getFieldValue }) => ({
                    required:
                      getFieldValue('Type') === 3
                        ? true
                        : getFieldValue('Type') === 5
                        ? true
                        : false,
                    message: 'Por favor ingrese un precio.',
                  }),
                ]}
                tooltip="Descuento en pesos/Monto a descontar."
              >
                <InputNumber
                  style={{ width: '100%' }}
                  // defaultValue={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  disabled={
                    promoType === 4 ? true : promoType === 6 ? true : false
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Form.Item
          label="Descripción"
          name="Description"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese una descripción.',
            },
          ]}
          tooltip="Breve descripción de la promoción, este concepto se mostrará en el ticket."
        >
          <TextArea showCount maxLength={100} />
        </Form.Item>

        {promoType !== 5 && promoType !== 6 && promoType !== 7 && (
          <Form.Item
            label="Producto"
            name="MenuItems"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione un elemento.',
              },
            ]}
            tooltip="La promoción se aplicará a los platillos, categorías o grupos seleccionados."
          >
            <TreeSelect {...itemProps} />
          </Form.Item>
        )}

        <Row>
          <Col xs={24} md={10} style={{ textAlign: 'center' }}>
            <Image width={160} src={imageUrl ? imageUrl : imageDefault} />
          </Col>

          <Col xs={14}>
            <Form.Item
              name="upload"
              label="Banner"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Imagen adjunta"
              tooltip="Banner promocional que se mostrará al cliente."
              // hidden={loading}
              dependencies={['ShowInHome']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value && getFieldValue('ShowInHome')) {
                      // console.log('entre');
                      return Promise.reject('Debe subir una imágen.');
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Upload
                name="logo"
                beforeUpload={beforeUpload}
                listType="picture"
                multiple={false}
                onChange={handleChange}
                fileList={filesList}
              >
                <Button icon={<UploadOutlined />}>Subir imagen</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Form.Item
              name="Delivery"
              label="A Domicilio"
              valuePropName="checked"
              {...layoutSwitch}
              tooltip="Tipo de pedido en la que se podrá aplicar la promoción."
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="PickUp"
              label="Para Llevar"
              valuePropName="checked"
              {...layoutSwitch}
              tooltip="Tipo de pedido en la que se podrá aplicar la promoción."
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          {/* <Col xs={8}>
                        <Form.Item
                            name="ToTable"
                            label="Para la Mesa"
                            valuePropName="checked"
                            {...layoutSwitch}
                            tooltip="Tipo de pedido en la que se podrá aplicar la promoción."
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                            />
                        </Form.Item>
                    </Col> */}
        </Row>

        <Form.Item
          label="Tipo de Fechas"
          name="DurationType"
          rules={[
            {
              required: false,
              message: 'Por favor seleccione un dato.',
            },
          ]}
          tooltip="Se podrá configurar horario específico dentro de la fecha de vigencia."
        >
          <Select placeholder="Seleccione una tipo" onChange={handleTypeShow}>
            <Option value="range-dates">Rango de fecha</Option>
            <Option value="customize-days">Personalizado por dias</Option>
          </Select>
        </Form.Item>

        {showPeriod === 'range-dates' && (
          <Form.Item
            {...layoutDatePicker}
            label="Vigencia"
            name="dateLimitRange"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una fecha limite.',
              },
            ]}
            tooltip="Rango de fechas de inicio y fin para la promoción."
          >
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        )}

        {showPeriod === 'customize-days' && (
          <>
            <Form.Item
              name="ApplicableDays"
              label="Días aplicables"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione al menos un día.',
                },
              ]}
              tooltip="Días donde se aplicará la promoción."
            >
              <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                style={{ width: '100%' }}
                options={options}
              />
            </Form.Item>
            <Form.Item
              name="timeDays"
              label="Hora de Inicio y Fin "
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione las horas.',
                },
              ]}
              tooltip="Hora de inicio y fin donde se aplicará la promoción referente a los dias seleccionados."
            >
              <TimePicker.RangePicker format={format} />
            </Form.Item>
          </>
        )}

        {/* HORARIO */}

        <Row>
          <Col xs={8}>
            <Form.Item
              name="Active"
              label="Activo"
              valuePropName="checked"
              {...layoutSwitch}
              tooltip="Se podrá Activar/Desactivar la promoción aún y que se encuentre dentro de la fecha de vigencia."
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
                    <Col xs={8}>
                        <Form.Item
                            name="Available"
                            label="Disponible"
                            valuePropName="checked"
                            {...layoutSwitch}
                            tooltip="Se podrá Activar/Desactivar la promoción aún y que se encuentre dentro de la fecha de vigencia."
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}

        {/* {((promoType === 1 && codeChecked) || (promoType === 2 && codeChecked)) && (
                    <Row>
                        <Col xs={24}>
                            <Form.Item
                                name="MaxPromos"
                                label="Número de cupones disponibles"
                                {...layoutCoupon}
                                tooltip="Si la cantidad es 0 se podrá utilizar ilimitadas veces"
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                )} */}

        <Form.Item
          name="ShowInHome"
          label="Mostrar banner en el inicio"
          valuePropName="checked"
          {...layoutSwitch}
          tooltip="Permite visualizar la imagen de la promoción en el apartado de inicio."
        >
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>

        <Form.Item {...tailLayout} style={{ marginTop: 20 }}>
          <Button type="primary" htmlType="submit" block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddPromoForm;

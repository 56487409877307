import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  InputNumber,
  Spin,
  Select,
  notification,
  Divider,
  Row,
  Col,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { addCompanyApi, addEditQRToCompanyApi } from '../../../../api/company';
import { messageError } from '../../../../utils/general';
import { getAccessTokenApi } from '../../../../api/auth';
import Modal from '../../../General/Modal';
import AddressForm from '../../../Client/AddressForm';
import { mexicanStates } from '../../../../utils/mexicanStates';
import moment from 'moment';
import AddUserForm from '../../../SuperAdmin/ListUsers/AddUserForm/AddUserForm';

export default function AddEditCompany({
  setIsVisibleAddModal,
  setReloadRestaurants,
  setModalContent,
}) {
  const [loading, setLoading] = useState(false);
  const [addressRest, setAddressRest] = useState({
    address: null,
    zipCode: null,
    neighborhood: null,
    city: null,
    state: null,
  });
  const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [form] = Form.useForm();
  const { Option } = Select;

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };
  const tailLayout = {
    wrapperCol: { offset: 6, span: 12 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  const tailLayoutButton = {
    wrapperCol: {
      offset: 0,
      span: 24,
    },
  };
  const tailLayoutUrl = {
    wrapperCol: {
      offset: 1,
      span: 22,
    },
  };
  useEffect(() => {
    if (
      addressRest.neighborhood &&
      addressRest.address &&
      addressRest.zipCode &&
      addressRest.city &&
      addressRest.state
    ) {
      form.setFieldsValue({
        Address: addressRest.address,
        ZipCode: addressRest.zipCode.toString(),
        Neighborhood: addressRest.neighborhood,
        City: addressRest.city,
        State: addressRest.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressRest]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const createCompanyIdhandle = (e) => {
    let text = e.target.value;
    const arrayText = text.split(' ');
    let companyId = '';
    arrayText.forEach((element) => {
      if (element !== '') {
        companyId = companyId + capitalizeFirstLetter(element);
      }
    });
    form.setFieldsValue({
      CompanyId: companyId,
    });
  };

  const onFinish = async (values) => {
    const token = getAccessTokenApi();
    values['CompanyId'] = values.CompanyId.replace(/\s/g, '');
    values['FiscalName'] = '';
    values['Phone'] = '999999999999';
    values['RFC'] = 'DEMO991020DFF';
    values['UseStripe'] = true;
    values['PWAURL'] = 'https://apptest.controlbsys.com/';
    values['ZipCode'] = values.ZipCode.toString();
    setLoading(true);
    const result = await addCompanyApi(token, values);
    setLoading(false);

    if (result) {
      if (result.statusCode === 200) {
        //create code QR
        const data = {
          CompanyId: values['CompanyId'],
          Name: values['Name'],
          Url: values['UrlQR'],
          StartDate: moment().format('YYYY-MM-DD'),
        };
        addEditQRToCompanyApi(token, data).then((response) => {
          if (response) {
            if (response.statusCode === 200) {
              notification['success']({
                message: 'Compañia creada exitosamente',
              });
              setReloadRestaurants(true);

              setModalContent(
                <AddUserForm
                  companyId={values['CompanyId']}
                  setIsVisibleModal={setIsVisibleAddModal}
                  setreloadUsers={setReloadRestaurants}
                />
              );
              // setTimeout(() => {
              //     setIsVisibleAddModal(false);
              //     setReloadRestaurants(true);
              // }, 1500);
            } else {
              notification['error']({
                message: 'Ocurrio un error al crear el código QR.',
              });
            }
          }
        });
      } else {
        let msgError = 'Ocurrió un error, intentelo mas tarde';
        if (result.statusCode === 409 || result.statusCode === 404) {
          msgError = result.desciption;
        }
        if (result.status === 400) {
          if (result.errors.RFC) {
            msgError = result.errors.RFC[0];
          }
          if (result.errors.InstagramURL) {
            msgError = result.errors.InstagramURL[0];
          }
          if (result.errors.FacebookURL) {
            msgError = result.errors.FacebookURL[0];
          }
          if (result.errors.URL) {
            msgError = 'El campo PÁGINA WEB no es una dirección válida.';
          }
        }
        notification['error']({
          message: msgError,
        });
      }
    } else {
      notification['error']({
        message: messageError(),
      });
    }
  };

  return (
    <div className="add-edit-company">
      <Spin spinning={loading}>
        <Form
          {...layout}
          form={form}
          name="control-ref"
          onFinish={onFinish}
          initialValues={{ Active: true }}
        >
          <Form.Item
            name="Name"
            label="Nombre de la compañía"
            rules={[{ required: true }]}
          >
            <Input
              placeholder="Demo Company"
              onChange={createCompanyIdhandle}
            />
          </Form.Item>
          <Form.Item
            name="CompanyId"
            label="Id de la compañía"
            rules={[{ required: true }]}
            tooltip="No debe llevar espacios este campo."
          >
            <Input placeholder="DemoCompany" disabled />
          </Form.Item>

          <Form.Item
            name="Email"
            label="Correo electrónico"
            hidden={loading}
            rules={[
              {
                type: 'email',
                message: 'El correo electrónico no es válido',
              },
              {
                required: true,
                message: 'Por favor ingrese un correo electrónico',
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
                        className='perfil-form__container-button'
                        hidden={loading}
                        {...tailLayoutButton}
                    >
                        <Button
                            icon={<EditOutlined />}
                            type='primary'
                            size='large'
                            block
                            onClick={() => setIsModalVisibleAddress(true)}
                            style={{ marginBottom: 20 }}
                            ghost
                        >
                            Agregar dirección
                        </Button>
                    </Form.Item> */}

          <Form.Item name="Address" label="Calle y Número">
            <Input />
          </Form.Item>

          <Form.Item
            name="ZipCode"
            label="Código Postal"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Por favor ingrese el código postal',
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || value.toString().length === 5) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    'Por favor ingresa código postal válido'
                  );
                },
              }),
            ]}
          >
            <InputNumber
              placeholder="Código Postal"
              style={{
                width: '100%',
              }}
            />
          </Form.Item>

          <Form.Item
            name="Neighborhood"
            label="Colonia"
            hidden={loading}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="City"
            label="Ciudad"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            disabled
            name="State"
            label="Estado"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Seleccione un estado">
              {mexicanStates.map((state) => {
                return (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="Latitude"
            label="Latitud de la compañía"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{
                width: '100%',
              }}
              placeholder="27.490668"
            />
          </Form.Item>

          <Form.Item
            name="Longitude"
            label="Longitud de la compañia "
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{
                width: '100%',
              }}
              placeholder="-109.9614298"
            />
          </Form.Item>

          {/* <Form.Item name='RFC' label='RFC'>
                        <Input placeholder='RFC' />
                    </Form.Item> */}

          <Form.Item
            name="FacebookURL"
            label="Facebook URL"
            tooltip="la url debe empezar con http:// o https://"
          >
            <Input placeholder="Facebook URL" />
          </Form.Item>

          <Form.Item
            name="InstagramURL"
            label="Instagram URL"
            tooltip="la url debe empezar con http:// o https://"
          >
            <Input placeholder="Instagram URL" />
          </Form.Item>

          <Form.Item
            name="URL"
            label="Página web de la compañía"
            tooltip="la url debe empezar con http:// o https://"
          >
            <Input placeholder="http://www.prueba.com" />
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item
                name="Active"
                valuePropName="checked"
                {...tailFormItemLayout}
              >
                <Checkbox disabled>Restaurante Activo</Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="IsTestingCompany"
                valuePropName="checked"
                {...tailFormItemLayout}
              >
                <Checkbox>Modo prueba</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider>Información del QR</Divider>
          <Form.Item
            name="UrlQR"
            tooltip="Url del sitio dentro de controlB"
            label="Url de control B"
            rules={[{ required: true }]}
            {...tailLayoutUrl}
          >
            <Input placeholder="https://www.test.controlbsys..com" />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              //   disabled={disableButton}
              block
            >
              Enviar
            </Button>
          </Form.Item>
        </Form>
        <Modal
          isVisible={isModalVisibleAddress}
          setIsVisible={setIsModalVisibleAddress}
          title={'Dirección del restaurante'}
          footer={false}
          destroyOnClose
        >
          <AddressForm
            type="adminPerfil"
            setAddressRest={setAddressRest}
            setIsModalVisibleAddress={setIsModalVisibleAddress}
            setDisableButton={setDisableButton}
          />
        </Modal>
      </Spin>
    </div>
  );
}

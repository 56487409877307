import React, { useEffect, useState } from "react";
import {
  Layout,
  Collapse,
  Typography,
  Row,
  Col,
  Avatar,
  Button,
  List,
  Divider,
  Image,
  Skeleton,
  Modal as ModalAntd,
  notification,
} from "antd";
import {
  CaretRightOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CloseCircleTwoTone,
  CloseSquareTwoTone,
  CheckSquareTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import Modal from "../../../components/General/Modal";
import EditGroup from "../../../components/Admin/Menu/EditGroup";
import EditCategoryForm from "../../../components/Admin/Menu/EditCategoryForm";
import CategoryForm from "../../../components/Admin/Menu/CategoryForm";
import DishForm from "../../../components/Admin/Menu/DishForm";
import EditDishForm from "../../../components/Admin/Menu/EditDishForm";
import AddSectionForm from "./SectionForm/AddSectionForm";
import EditSectionForm from "./SectionForm/EditSectionForm";
import ImageDefault from "../../../assets/img/png/camara.ico";
import { getAccessTokenApi, logout } from "../../../api/auth";
import { getCompanyIdApi } from "../../../api/company";
import {
  deleteGroupMenu,
  deleteCategoryMenu,
  deleteItemMenu,
  getMenuGroupApi,
} from "../../../api/menu";
import {
  getAllItemSections,
  deleteItemSection,
} from "../../../api/itemSections";
import { formatMoney } from "../../../utils/general";
import useAuth from "../../../hooks/useAuth";
import AddIngredientForm from "../../../components/Admin/Menu/IngredientForm/AddIngredientForm";
import EditIngredientForm from "./IngredientForm/EditIngredientForm/EditIngredientForm";
import "./Menu.scss";

export default function Menu(props) {
  const { Panel } = Collapse;

  const { menu, loading, showPickUpMenu } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [titleModal, setTitleModal] = useState("Titulo");
  const [contextModal, setContextModal] = useState(null);
  let [menuAdmin, setMenuAdmin] = useState([]);
  let [updateMenu, setUpdateMenu] = useState(false);
  const [groupSearches, setGroupSearches] = useState([]);
  const [loadingItems, setLoadingItems] = useState(loading);
  const [activeKey, setActiveKey] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = useAuth();

  const { confirm } = ModalAntd;

  useEffect(() => {
    const roleId =
      user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if (roleId === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user]);

  useEffect(() => {
    setMenuAdmin(menu);
    return () => {
      setMenuAdmin([]);
    };
  }, [menu]);

  useEffect(() => {
    setLoadingItems(loading);
  }, [loading]);

  useEffect(() => {
    setMenuAdmin(menuAdmin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMenu]);

  const token = getAccessTokenApi();
  const companyId = getCompanyIdApi();

  const onCreated = (created) => {
    if (created && Object.entries(created).length > 0) {
    }
  };

  const showModal = (
    title,
    element,
    type,
    groupId,
    itemSection,
    setItemSection
  ) => {
    setIsModalVisible(true);
    setTitleModal(title);

    switch (type) {
      case "Edit-Group":
        let imageGroup = "";
        if (element.groupImage !== null) {
          const { extension, imageData } = element.groupImage;
          imageGroup = `data:image/${extension};base64, ${imageData}`;
        }

        setContextModal(
          <EditGroup
            menuAdmin={menu}
            setMenuAdmin={setMenuAdmin}
            element={element}
            imageGroup={imageGroup}
            setIsModalVisible={setIsModalVisible}
          />
        );

        break;

      case "Add-Category":
        setContextModal(
          <CategoryForm
            menuAdmin={menu}
            setMenuAdmin={setMenuAdmin}
            element={element}
            setIsModalVisible={setIsModalVisible}
          />
        );
        break;

      case "Edit-Category":
        setContextModal(
          <EditCategoryForm
            menuAdmin={menu}
            setMenuAdmin={setMenuAdmin}
            element={element}
            setIsModalVisible={setIsModalVisible}
          />
        );
        break;

      case "Add-Dish":
        setContextModal(
          <DishForm
            menuAdmin={menu}
            showPickUpMenu={showPickUpMenu}
            setMenuAdmin={setMenuAdmin}
            element={element}
            setIsModalVisible={setIsModalVisible}
          />
        );
        break;

      case "Edit-Dish":
        let imageDish = "";
        if (element.itemImage !== null) {
          const { extension, imageData } = element.itemImage;
          imageDish = `data:image/${extension};base64, ${imageData}`;
        }

        setContextModal(
          <EditDishForm
            menuAdmin={menu}
            showPickUpMenu={showPickUpMenu}
            setMenuAdmin={setMenuAdmin}
            element={element}
            imageDish={imageDish}
            groupId={groupId}
            setIsModalVisible={setIsModalVisible}
          />
        );
        break;
      case "AddIngredient":
        setContextModal(<AddIngredientForm></AddIngredientForm>);
        break;
      case "EditIngredient":
        setContextModal(<EditIngredientForm></EditIngredientForm>);
        break;

      case "AddSection":
        setContextModal(
          <AddSectionForm
            menuAdmin={menu}
            element={element}
            setIsModalVisible={setIsModalVisible}
            setMenuAdmin={setMenuAdmin}
            itemSection={itemSection}
          />
        );
        break;
      case "EditSection":
        setContextModal(
          <EditSectionForm
            element={element}
            setIsModalVisible={setIsModalVisible}
            itemSection={itemSection}
            setItemSection={setItemSection}
            // groupId={groupId} //0
          />
        );
        break;

      default:
        break;
    }
  };

  const showDeleteConfirm = (
    title,
    idGroup,
    type,
    idCategory,
    idDish,
    itemSection,
    setItemSection
  ) => {
    confirm({
      title: `¿Estás seguro que deseas borrar ${title}?`,
      icon: <ExclamationCircleOutlined />,
      content: "No podrás recuperarlo después...",
      onOk() {
        setUpdateMenu(false);

        switch (type) {
          case "order":
            deleteGroup(title, idGroup);
            break;
          case "category":
            deleteCategory(title, idGroup, idCategory);
            break;
          case "dish":
            deleteDish(title, idGroup, idCategory, idDish);
            break;
          case "section":
            deleteSection(title, idGroup, itemSection, setItemSection);
            break;
          default:
            break;
        }
      },
      onCancel() {},
    });
  };

  const deleteGroup = (title, idGroup) => {
    let data = {
      CompanyId: companyId,
      GroupId: idGroup,
    };

    deleteGroupMenu(token, data)
      .then((res) => {
        if (res === undefined) {
          notification["error"]({
            message: `Ocurrio un porblema, ${title} no se pudo eliminar, intentelo más tarde`,
          });
          return;
        }
        if (res?.statusCode === 401) {
          notification["error"]({
            message: "Usuario no autorizado.",
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1200);

          return false;
        }
        if (res.message) {
          notification["error"]({
            message: `Ocurrio un porblema, ${title} no se pudo eliminar`,
          });
          setUpdateMenu(true);

          return false;
        } else {
          const found = menuAdmin.findIndex((obj) => obj.groupId === idGroup);
          menuAdmin.splice(found, 1);
          setMenuAdmin(menuAdmin);
          setUpdateMenu(true);

          notification["success"]({
            message: `Se elimino con éxito el ${title}`,
          });

          return true;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const deleteCategory = (title, idGroup, idCategory) => {
    let data = {
      CompanyId: companyId,
      GroupId: idGroup,
      CategoryId: idCategory,
    };

    deleteCategoryMenu(token, data)
      .then((res) => {
        if (res?.statusCode === 401) {
          notification["error"]({
            message: "Usuario no autorizado.",
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1200);

          return false;
        }
        if (res.message) {
          notification["error"]({
            message: `Ocurrio un porblema, ${title} no se pudo eliminar`,
          });
          setUpdateMenu(true);

          return false;
        } else {
          const found = menuAdmin.findIndex((obj) => obj.groupId === idGroup);
          const foundCat = menuAdmin[found].categories.findIndex(
            (cat) => cat.categoryId === idCategory
          );
          menuAdmin[found].categories.splice(foundCat, 1);
          setMenuAdmin(menuAdmin);
          setUpdateMenu(true);

          notification["success"]({
            message: `Se elimino con éxito la ${title}`,
          });

          return true;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const deleteDish = (title, idGroup, idCategory, idDish) => {
    let data = {
      CompanyId: companyId,
      CategoryId: idCategory,
      ItemId: idDish,
    };

    deleteItemMenu(token, data)
      .then((res) => {
        if (res?.statusCode === 401) {
          notification["error"]({
            message: "Usuario no autorizado.",
          });

          setTimeout(() => {
            logout();
            window.location.reload();
          }, 1200);

          return false;
        }
        if (res.message) {
          notification["error"]({
            message: `Ocurrio un porblema, ${title} no se pudo eliminar`,
          });
          setUpdateMenu(true);

          return false;
        } else {
          const found = menuAdmin.findIndex((obj) => obj.groupId === idGroup);
          const foundCat = menuAdmin[found].categories.findIndex(
            (cat) => cat.categoryId === idCategory
          );
          const foundItem = menuAdmin[found].categories[
            foundCat
          ].items.findIndex((item) => item.itemId === idDish);
          menuAdmin[found].categories[foundCat].items.splice(foundItem, 1);
          setMenuAdmin(menuAdmin);
          setUpdateMenu(true);

          notification["success"]({
            message: `Se elimino con éxito la ${title}`,
          });

          return true;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const deleteSection = (title, idGroup, itemSection, setItemSection) => {
    deleteItemSection(token, idGroup)
      .then((res) => {
        if (res.status === 204) {
          const filtradas = itemSection.filter(
            (ele) => ele.itemSectionId !== idGroup
          );
          setItemSection(filtradas);

          notification["success"]({
            message: `Sección eliminada éxitosamente`,
          });
        }
        if (res.status === 400) {
          notification["error"]({
            message: `Ocurrio un porblema, ${title} no se pudo eliminar`,
          });
        }
        setUpdateMenu(true);

        return false;
      })
      .catch((err) => {
        return false;
      });
  };

  const callbackGroup = (key) => {
    if (key !== undefined) {
      let idGroup = parseInt(key);
      const foundIdGroup = groupSearches.find((id) => id === idGroup);
      if (foundIdGroup === undefined) {
        setLoadingItems(true);
        let dataApi = {
          CompanyId: companyId,
          GroupId: idGroup,
          MenuClient: false,
        };
        getMenuGroupApi(token, dataApi).then((resp) => {
          if (resp === undefined) {
            setActiveKey(key);
          } else if (resp !== undefined) {
            if (resp?.statusCode === 401) {
              notification["error"]({
                message: "Usuario no autorizado",
              });

              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return false;
            }
            setGroupSearches([...groupSearches, idGroup]);
            const found = menuAdmin.findIndex(
              (item) => item.groupId === resp.groupId
            );
            menuAdmin[found] = resp;
            setMenuAdmin(menuAdmin);
            setActiveKey(idGroup.toString());

            setLoadingItems(false);
          }
          setLoadingItems(false);
        });
      } else {
        setActiveKey(key);
      }
    }
  };

  const listData = [];

  for (let i = 0; i < 5; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar:
        "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    });
  }
  return (
    <>
      {loadingItems !== true && (
        // <Spin spinning={loadingItems} tip="Cargando..." >
        <Collapse
          collapsible="disabled"
          bordered={false}
          accordion
          defaultActiveKey={[activeKey]}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="menu-collapse"
          expandIconPosition="right"
          onChange={callbackGroup}
        >
          {menuAdmin &&
            menuAdmin !== undefined &&
            menuAdmin.length > 0 &&
            menuAdmin.map((ele) => {
              return (
                <Panel
                  header={<TitleOrder name={ele.name} image={ele.groupImage} />}
                  key={ele.groupId}
                  collapsible="disabled"
                  className="menu_panel"
                >
                  <ButtonIcon
                    category="order"
                    showModal={showModal}
                    priority={ele.priority}
                    element={ele}
                    showDeleteConfirm={showDeleteConfirm}
                    isAdmin={isAdmin}
                  />

                  {ele.categories &&
                    ele.categories.map((cat) => {
                      return (
                        <Collapse
                          key={cat.categoryId}
                          // defaultActiveKey={cat.categoryId}
                          className="menu-collapse__sub"
                          expandIconPosition="right"
                        >
                          <Panel header={cat.name} key={cat.categoryId}>
                            <ButtonIcon
                              category="category"
                              showModal={showModal}
                              isAdmin={isAdmin}
                              priority={cat.priority}
                              element={cat}
                              showDeleteConfirm={showDeleteConfirm}
                            />

                            <Divider>Platillos</Divider>

                            <Dish
                              item={cat.items}
                              showDeleteConfirm={showDeleteConfirm}
                              groupid={ele.groupId}
                              showModal={showModal}
                              isAdmin={isAdmin}
                              onCreated={onCreated}
                            />
                          </Panel>
                        </Collapse>
                      );
                    })}
                </Panel>
              );
            })}
        </Collapse>
        // </Spin>
      )}

      {loadingItems === true && (
        <List
          itemLayout="vertical"
          size="large"
          dataSource={listData}
          renderItem={(item) => (
            <List.Item key={item.title}>
              <Skeleton loading={loadingItems} active avatar>
                <List.Item.Meta
                  avatar={<Avatar src={item.avatar} />}
                  title={<a href={item.href}>{item.title}</a>}
                  description={item.description}
                />
                {item.content}
              </Skeleton>
            </List.Item>
          )}
        />
      )}

      <Modal
        destroyOnClose
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        title={titleModal}
        footer={false}
        // destroyOnClose
      >
        {contextModal}
      </Modal>
    </>
  );
}

function TitleOrder(props) {
  const { Title } = Typography;
  const { name, image } = props;
  let urlImage = "";

  if (image !== null) {
    const { extension, imageData } = image;
    urlImage = `data:image/${extension};base64, ${imageData}`;
  } else {
    urlImage = ImageDefault;
  }

  return (
    <Row gutter={[16, 16]} className="menu__header">
      <Col span={8}>
        <Avatar size={80} src={urlImage} />
      </Col>
      <Col span={16} className={["menu__header-right", "show-menu-admin"]}>
        <Title level={3}>{name}</Title>
      </Col>
    </Row>
  );
}

function ButtonIcon(props) {
  const { Title } = Typography;
  const { category, showModal, priority, element, showDeleteConfirm, isAdmin } =
    props;

  let titleModalEdit =
    category === "order" ? "Editar Grupo" : "Editar Categoría";
  let titleModalAdd =
    category === "order" ? "Agregar Categoría" : "Agregar Platillo";

  let actionAdd = "";
  let actionEdit = "";
  let titleDelete = "";
  let idCategory = 0;

  if (category === "order") {
    actionEdit = "Edit-Group";
    actionAdd = "Add-Category";
    titleDelete = `el grupo: ${element.name}`;
  } else if (category === "category") {
    actionEdit = "Edit-Category";
    actionAdd = "Add-Dish";
    titleDelete = `la categoría: ${element.name}`;
    idCategory = element.categoryId;
  }

  return (
    <Row gutter={[16, 16]}>
      <Col span={10}>
        <Title level={category === "order" ? 4 : 5}>
          Prioridad: {priority}
        </Title>
      </Col>
      <Col span={14}>
        <Row className="buttons__containers">
          <Col span={8}>
            <Button
              className="btn-icons"
              type="primary"
              shape="circle"
              size={category === "order" ? "large" : "default"}
              icon={<EditOutlined />}
              onClick={() => showModal(titleModalEdit, element, actionEdit)}
            />
          </Col>
          {isAdmin && (
            <Col span={8}>
              <Button
                className="btn-icons"
                type="primary"
                shape="circle"
                danger
                size={category === "order" ? "large" : "default"}
                icon={<DeleteOutlined />}
                onClick={() =>
                  showDeleteConfirm(
                    titleDelete,
                    element.groupId,
                    category,
                    idCategory
                  )
                }
              />
            </Col>
          )}
          <Col span={8}>
            <Button
              className={["btn-icons", "btn-add"]}
              type="primary"
              size={category === "order" ? "large" : "default"}
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => showModal(titleModalAdd, element, actionAdd)}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function Dish(props) {
  const { item, showDeleteConfirm, groupid, showModal, isAdmin, onCreated } =
    props;

  const data = item;

  return (
    <List
      className="dish-list"
      itemLayout="horizontal"
      dataSource={data}
      locale={{ emptyText: "No hay platillos" }}
      renderItem={(item) => (
        <List.Item
          className="dish-list__content align-items-menu"
          extra={
            <DishFooter
              item={item}
              showDeleteConfirm={showDeleteConfirm}
              showModal={showModal}
              onCreated={onCreated}
            />
          }
        >
          <List.Item.Meta
            avatar={
              <Image
                src={
                  item.itemImage !== null && item.itemImage !== undefined
                    ? `data:image/${item.itemImage.extension};base64, ${item.itemImage.imageData}`
                    : ImageDefault
                }
                width={150}
                height={150}
              />
            }
            title={item.name}
            description={
              <DishContent
                item={item}
                showDeleteConfirm={showDeleteConfirm}
                groupid={groupid}
                showModal={showModal}
                isAdmin={isAdmin}
              />
            }
          />
        </List.Item>
      )}
    />
  );
}

function DishContent(props) {
  const { item, showDeleteConfirm, groupid, showModal, isAdmin } = props;
  const { toGO, name, categoryId, itemId, toDeliver } = item; // add toTable
  const titleDelete = `el platillo: ${name}`;
  const category = "dish";
  const titleModal = "Editar Platillo";
  const action = "Edit-Dish";

  return (
    <>
      <Row className="dish-contents" gutter={[0, 10]}>
        <Col span={12}>
          <Button
            className="btn-icons"
            type="primary"
            shape="circle"
            icon={<EditOutlined />}
            onClick={() => showModal(titleModal, item, action, groupid)}
          />
        </Col>
        {isAdmin && (
          <Col span={12}>
            <Button
              className="btn-icons"
              type="primary"
              shape="circle"
              danger
              icon={<DeleteOutlined />}
              onClick={() =>
                showDeleteConfirm(
                  titleDelete,
                  groupid,
                  category,
                  categoryId,
                  itemId
                )
              }
            />
          </Col>
        )}
      </Row>

      <Row className="dish-switch" gutter={[0, 10]}>
        <Col
          span={24}
          xs={{ span: 24 }}
          lg={{ span: 20 }}
          className="dish-switch__left"
        >
          {!toGO && (
            <p>
              {" "}
              Menú para llevar{" "}
              <CloseSquareTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </p>
          )}
          {toGO && (
            <p>
              {" "}
              Menú para llevar{" "}
              <CheckSquareTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </p>
          )}
          {!toDeliver && (
            <p>
              {" "}
              Menú a domicilio{" "}
              <CloseSquareTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </p>
          )}
          {toDeliver && (
            <p>
              {" "}
              Menú a domicilio{" "}
              <CheckSquareTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </p>
          )}

          {/* {!toTable && <p>Menú a la mesa <CloseSquareTwoTone twoToneColor="#eb2f96" style={{ fontSize: '22px', marginLeft: '10px' }} /></p>}
                    {toTable && <p> Menú a la mesa <CheckSquareTwoTone twoToneColor="#52c41a" style={{ fontSize: '22px', marginLeft: '10px' }} /></p>} */}
        </Col>
      </Row>
    </>
  );
}

function DishFooter(props) {
  const { item, showModal, showDeleteConfirm, onCreated } = props;
  const { priority, price, description, available, itemId } = item;
  const [itemSection, setItemSection] = useState([]);
  const titleModalAdd = "Agregar sección";
  let type = "AddSection";

  const token = getAccessTokenApi();
  const companyId = getCompanyIdApi();

  useEffect(() => {
    getAllItemSections(token, companyId, itemId).then((response) => {
      if (response.statusCode) {
        if (response.statusCode !== 200) {
          setItemSection([]);
        }
      } else {
        setItemSection(response);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row className="dish-description">
        <Col span={24} xs={24} lg={7}>
          <h1>Prioridad: {priority}</h1>
        </Col>
        <Col span={24} xs={24} lg={7}>
          <h1>Precio: $ {formatMoney(price)}</h1>
        </Col>
        <Col span={24} xs={24} lg={24}>
          {!available && (
            <h1>
              Disponible:{" "}
              <CloseCircleTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </h1>
          )}
          {available && (
            <h1>
              Disponible:{" "}
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "22px", marginLeft: "10px" }}
              />
            </h1>
          )}

          {/* <Row>
                    <Col span={18} xs={{ span: 18 }} lg={{ span: 12 }}className="dish-switch__left">
                        <h1>Disponible <CloseSquareTwoTone twoToneColor="#eb2f96" /></h1>
                        
                    </Col>
                    <Col span={6} xs={{ span: 6}} lg={{ span: 8 }} className="dish-switch__right">
                        <Title level={4} ><CloseSquareTwoTone twoToneColor="#eb2f96" /></Title>
                    </Col>
                </Row> */}
        </Col>
        <Col span={24} xs={24} lg={24}>
          <h1>
            Descripción:{" "}
            {description === "" ? "No hay descripción" : description}{" "}
          </h1>
        </Col>
      </Row>
      <Row>
        <Layout className="background-layout">
          <Row className="space-content">
            <Col xs={21} md={23} lg={23}>
              <Divider>Secciones Especiales</Divider>
            </Col>
            <Col xs={2} md={1} lg={1}>
              <Button
                className={["btn-icons", "btn-add", "btn-margin-add"]}
                type="primary"
                size={"middle"}
                shape="circle"
                icon={<PlusOutlined />}
                onClick={() =>
                  showModal(
                    titleModalAdd,
                    itemId,
                    type,
                    setItemSection,
                    itemSection
                  )
                }
              />
            </Col>
          </Row>
          <SectionTable
            itemId={itemId}
            showDeleteConfirm={showDeleteConfirm}
            showModal={showModal}
            onCreated={onCreated}
            itemSection={itemSection}
            setItemSection={setItemSection}
          />
        </Layout>
      </Row>
    </>
  );
}
function SectionTable(props) {
  const { showDeleteConfirm, showModal, itemSection, setItemSection } = props;
  const titleModal = "Editar Sección";
  // let type = 'EditSection';

  return (
    <>
      {itemSection.length >= 1 &&
        itemSection.map((ele) => {
          return (
            <div key={ele.itemSectionId} className="border-layout">
              <Row className=" space-content align-items-center">
                <Col className="height-items" xs={16} md={16} lg={16}>
                  <h1>{ele.name}</h1>
                </Col>
                <Row xs={8} md={8} lg={8} className="space-content">
                  <Button
                    className="btn-icons"
                    type="primary"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() =>
                      showModal(
                        titleModal,
                        ele,
                        "EditSection",
                        0,
                        itemSection,
                        setItemSection
                      )
                    }
                  />
                  <Button
                    className="btn-icons margin-button-ing"
                    type="primary"
                    shape="circle"
                    danger
                    icon={<DeleteOutlined />}
                    onClick={() =>
                      showDeleteConfirm(
                        `la sección: ${ele.name}`,
                        ele.itemSectionId,
                        "section",
                        0,
                        0,
                        itemSection,
                        setItemSection
                      )
                    }
                  />
                </Row>
              </Row>
            </div>
          );
        })}
      {itemSection.length === 0 && <p>NO HAY SECCIONES</p>}
    </>
  );
}

import React, { useEffect, useState } from 'react';
import {
    Form,
    Button,
    Select,
    notification,
    Spin,
    Typography,
    Divider,
} from 'antd';
import { getAccessTokenApi } from '../../../../api/auth';
import { messageError, messageErrorGeneral } from '../../../../utils/general';
import { getManagersApi, linkToFatherCompanyApi } from '../../../../api/managers';

export default function LinkSucursal(props) {
    const {
        companyId,
        setIsVisibleModal,
        setReloadRestaurants,
        restaurant
    } = props;
    const [loading, setLoading] = useState(false);
    const token = getAccessTokenApi();
    const [form] = Form.useForm();
    const [companyFathers, setCompanyFathers] = useState([]);
    const [loadingCompanies, setLoadingCompanies] = useState(true)
    const { Option } = Select;
    const { Paragraph, Text } = Typography;
    const layout = {
        labelCol: { span: 11 },
        wrapperCol: { span: 13 },
    };

    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };

    useEffect(() => {
        getManagersApi(token).then(response=> {
            setLoadingCompanies(false)
            if(response?.statusCode === 200){
                setCompanyFathers(response.result)
            } else {
                notification["error"]({
                    message: messageErrorGeneral()
                })
            }
        }).catch(err=>console.log(err))
    }, [])

    const onFinish = (values) => {
        setLoading(true);

        linkToFatherCompanyApi(token, companyId, values).then((response) => {
            if (response) {
                if (response.statusCode === 200) {
                    notification['success']({
                        message: 'Actualización exitosa.',
                    });
                    setLoading(false);
                    setIsVisibleModal(false);
                    setReloadRestaurants(true);
                } else {
                    let msg = messageError();

                    if (
                        response.statusCode === 409 ||
                        response.statusCode === 404 ||
                        response.statusCode === 400
                    ) {
                        msg = response.description;
                    }

                    notification['error']({
                        message: msg,
                    });
                    setLoading(false);
                }
            } else {
                let msg = messageError();

                notification['error']({
                    message: msg,
                });
                setLoading(false);
            }
        });
    };

    const handleGetNameFather = (idFather) =>{
        const restFather = companyFathers.find(res=>res.id === idFather);
        if(restFather){
            return restFather.name;
        } else {
            return ''
        }
    }


    return (
        <Spin spinning={loading} tip='Cargando...'>
            {!loadingCompanies && <Paragraph strong>
                <Text type='secondary'>Compañia en la que se encuentra: </Text>
                {restaurant.fatherCompanyId !== null ? handleGetNameFather(restaurant.fatherCompanyId) : 'Ninguno'}
            </Paragraph>}

            <Divider>Seleccione restaurante padre </Divider>


                <Form
                    {...layout}
                    form={form}
                    name='control-hooks'
                    onFinish={onFinish}
                >
                    <Form.Item
                        name='FatherCompanyId'
                        label='Seleccione una compañia'
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder='Seleccione una compañia'
                        >
                            {companyFathers.map(rest=>(<Option value={rest.id}>
                                {rest.name}
                            </Option>))}
                        </Select>
                    </Form.Item>

                  

                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit' block>
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            
        </Spin>
    );
}

import React, { useState, createContext, useEffect } from 'react';
import { notification } from 'antd';
import { getAccessTokenApi, getEmailUserApi, logout } from '../api/auth';
import { getUserApi } from '../api/user';
import { getCompanyApi } from '../api/company';
import { COMPANY_ID } from '../utils/constants';

export const UserContext = createContext();

export default function UserProvider(props) {
  const { children } = props;
  const [person, setPerson] = useState({
    userType: 0,
    companyId: null,
    name: '',
    shortName: '',
    address: null,
    address2: null,
    city: null,
    state: null,
    claims: [],
    neighborhood: null,
    phoneNumber: '',
    registrationNumber: null,
    vehicle: null,
    zipCode: null,
    loading: true,
  });

  useEffect(() => {
    checkPerson(setPerson);
  }, []);

  return (
    <UserContext.Provider value={{ person, setPerson }}>
      {children}
    </UserContext.Provider>
  );
}

function checkPerson(setPerson) {
  const accessToken = getAccessTokenApi();
  if (accessToken) {
    const email = getEmailUserApi(accessToken);

    getUserApi(email).then((response) => {
      // console.log('Resp de la api Get User');
      // console.log(JSON.parse(response, 'get user api'));

      if (response?.statusCode === 401) {
        notification['error']({
          message: 'Usuario no autorizado.',
        });

        setTimeout(() => {
          logout();
          window.location.reload();
        }, 1500);
        return;
      }
      if (response !== undefined || response) {
        const data = JSON.parse(response);
        const companyId =
          data.companyId !== null ? data.companyId : 'TienditaDeLaEsquina';

        localStorage.setItem(COMPANY_ID, companyId);

        const firstName = data.name.split(' ');
        let completeName = '';

        if (firstName[1] !== undefined) {
          completeName = firstName[0];
          // completeName = firstName[0]+" "+firstName[1];
        } else {
          completeName = firstName[0];
        }

        let personInfo = {
          // ...person,
          userType: data.userType,
          companyId: companyId,
          name: data.name,
          lastName: data.lastName,
          shortName: completeName,
          address: data.address,
          phoneNumber: data.phoneNumber,
          city: data.city,
          neighborhood: data.neighborhood,
          zipCode: data.zipCode,
          state: data.state,
          loading: false,
          emailConfirmed: data.emailConfirmed,
          email: data.email,
          active: data.active,
        };

        //OBTENER IMAGEN DEL RESTAURANTE
        getCompanyApi(accessToken, data.companyId).then((res) => {
          if (res) {
            if (res.statusCode) {
              if (res.statusCode === 401) {
                notification['error']({
                  message: 'Usuario no autorizado.',
                });

                // setTimeout(() => {
                //     logout();
                //     window.location.reload();
                // }, 1500);
                return;
              }
            } else {
              if (res !== undefined) {
                if (!res.error) {
                  if (res.companyImage) {
                    const extensionImage = res.companyImage?.extension;
                    const imageProfile = `data:image/${extensionImage};base64, ${res.companyImage?.imageData}`;
                    personInfo['avatar'] = imageProfile;
                  }
                }
              }
            }
          }
        });
        setPerson(personInfo);
      } else {
        setPerson({
          userType: 0,
          companyId: null,
          name: '',
          avatar: '',
          loading: false,
        });
      }
    });
  } else {
    setPerson({
      userType: 0,
      companyId: null,
      name: '',
      avatar: '',
      loading: false,
    });
  }
}

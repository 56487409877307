import React, { useState } from 'react';
import { Form, Input, Button, Spin, notification } from 'antd';
import { forgotPasswordApi } from '../../../api/user';

import './ForgotPassword.scss';

const ForgotPasswordForm = (props) => {
  const { setIsModalVisible } = props;
  const [loading, setLoading] = useState(false);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };

  const onFinish = async (values) => {
    values['companyId'] = 'TienditaDeLaEsquina';
    setLoading(true);

    const result = await forgotPasswordApi(values);

    if (result === undefined) {
      setLoading(false);

      notification['error']({
        message: 'Ocurrió un error, Intentelo mas tarde',
      });

      setIsModalVisible(false);

      return false;
    }

    if (result.statusCode === 200) {
      setLoading(false);

      notification['success']({
        message: result.description,
        description:
          'Al hacer el proceso de recuperación recibirás un correo con un enlace para realizar el proceso. Por favor pulsa enviar y revisa tu correo.',
        duration: 4,
      });

      setIsModalVisible(false);
    } else {
      let messageError = 'Ocurrió un error, Intentelo otra vez';

      if (result.statusCode === 409) {
        messageError = result.message;
      }

      if (result.statusCode === 400) {
        messageError =
          'Ocurrió un error, por favor revise que su correo electrónico este correcto.';
      }

      setLoading(false);

      notification['error']({
        message: messageError,
      });
    }
  };

  return (
    <Spin tip="Cargando..." spinning={loading}>
      <Form
        {...layout}
        name="forgotPassword"
        onFinish={onFinish}
        className="forgot-password"
      >
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'El correo electrónico no es válido',
            },
            {
              required: true,
              message: 'Por favor ingresa un correo electrónico',
            },
          ]}
        >
          <Input placeholder="ejemplo@company.com" />
        </Form.Item>

        <Form.Item {...tailLayout} className="fotgot-password__button">
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ForgotPasswordForm;

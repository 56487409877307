import React, { useState, useEffect } from "react";
import {
  Spin,
  Form,
  InputNumber,
  Select,
  Row,
  Col,
  Divider,
  Button,
  Card,
  Popconfirm,
  Typography,
  message,
  notification,
} from "antd";
import { DeleteOutlined, TagFilled } from "@ant-design/icons";
import { getAccessTokenApi, logout } from "../../../api/auth";
import { getCompanyApi, getCompanyIdApi } from "../../../api/company";
import { editOrder, deleteOrderDetailApi } from "../../../api/order";
import { formatMoney, userLogOut } from "../../../utils/general";
import DeliveryCostForm from "../../../components/Client/DeliveryCostForm";
import Modal from "../../../components/General/Modal";
import { useInfoCompany } from "../../../hooks/useInfoCompany";
// import moment from 'moment';

import "./EditOrderForm.scss";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const EditOrderForm = (props) => {
  const { setIsModalVisible, order, setReloadPendingOrders, setReloadOrders } =
    props;
  const token = getAccessTokenApi();
  const companyId = getCompanyIdApi();
  const [orderForm, setOrderForm] = useState([]);
  const [orderState, setOrderState] = useState(order);
  const [reloadOrder, setReloadOrder] = useState(false);
  const [typeDelivery, setTypeDelivery] = useState(order.orderType);
  const [showChangeOf, setShowChangeOf] = useState(
    order.payType === 1 ? true : false
  );
  const [form] = Form.useForm();
  let [subtotal, setSubtotal] = useState(0);
  const { infoCompany, setInfoCompany } = useInfoCompany(null);
  const { Option } = Select;
  const { Text } = Typography;
  const [loadingForm, setLoadingForm] = useState(false);
  const [costDelivery, setCostdelivery] = useState(order.delivery);
  const [address, setAddress] = useState(order.clientAddress);
  const [distance, setDistance] = useState(order.distance);
  const [isModalVisibleCost, setIsModalVisibleCost] = useState(false);
  const [tipOrder, setTipOrder] = useState(order.tip);
  const [descuentoItem, setDescuentoItem] = useState(0);
  const [descuentoOrden, setDescuentoOrden] = useState(0);
  const [sumaItem, setSumaItem] = useState(0);
  const [totalElementos, setTotalElementos] = useState([]);
  // const now = moment();

  useEffect(() => {
    if (token == null) {
      userLogOut();
    }
  }, [token]);

  useEffect(() => {
    setOrderForm([
      {
        name: ["orderType"],
        value: order.orderType,
      },
      {
        name: ["clientAddress"],
        value: order.clientAddress,
      },
      // {
      //     name: ['table'],
      //     value: order.tableNumber=== 0 ? "": order.tableNumber,
      // },
      {
        name: ["payType"],
        value: order.payType,
      },
      // {
      //     name: ['deliveryCost'],
      //     value: costDelivery,
      // },
      // {
      //     name: ['deliveryCost'],
      //     value: order.delivery,
      // },
      {
        name: ["changeOf"],
        value: order.changeOf === 0 ? order.total.toFixed(2) : order.changeOf,
        // value: order.changeOf === 0 ?  order.orderType ===2 ? formatMoney(order.subTotal+order.tip+order.delivery) : formatMoney(order.subTotal+order.tip)  : formatMoney(order.changeOf),
      },
    ]);

    getCompanyApi(token, companyId).then((response) => {
      if (response.error) {
        return false;
      }

      setInfoCompany({
        Location: response.address,
        DeliveryCostKM: response.deliveryCostKM,
        DeliveryExtraKmCost: response.deliveryExtraKmCost,
        DeliverryMaxDistance: response.deliverryMaxDistance,
        ServiceMaxDistance: response.serviceMaxDistance,
        DeliveryCost: response.deliveryCost,
        Latitude: response.latitude,
        Longitude: response.longitude,
        OrderMinimumPurchase: response.orderMinimumPurchase,
      });
    });

    return () => {
      form.resetFields();
      setOrderForm([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, costDelivery]);

  useEffect(() => {
    let suma = 0;
    let productosgratis = 0;
    let restarCupon = 0;
    let tmpTotal = [];
    let extraItems = [];

    orderState.details.forEach((element) => {
      // const encontrado = orderState.orderPromotions.find(orderPromotion => orderPromotion.detailId === element.detailId);

      if (element.detailPromotions.length !== 0) {
        switch (element.detailPromotions[0].type) {
          case 1:
            productosgratis = Math.trunc(element.quantity / 2);
            //Extras
            if (element.quantity % 2 > 0) {
              const found = extraItems.findIndex(
                (extra) => extra.id === element.itemId
              );

              if (found === -1) {
                extraItems.push({
                  id: element.itemId,
                  quantity: element.quantity % 2,
                  promoType: 1,
                  price: element.unitPrice,
                });
              } else {
                extraItems[found].quantity =
                  extraItems[found].quantity + (element.quantity % 2);
              }
            }
            if (element.orderDetailUom !== null) {
              restarCupon =
                productosgratis *
                (element.unitPrice * element.orderDetailUom.units);
              setDescuentoItem(restarCupon);
            } else {
              restarCupon = productosgratis * element.unitPrice;
              setDescuentoItem(restarCupon);
            }
            break;
          case 2:
            productosgratis = Math.trunc(element.quantity / 3);
            if (element.quantity % 3 > 0) {
              const found = extraItems.findIndex(
                (extra) => extra.id === element.itemId
              );

              if (found === -1) {
                extraItems.push({
                  id: element.itemId,
                  quantity: element.quantity % 3,
                  promoType: 2,
                  price: element.unitPrice,
                });
              } else {
                extraItems[found].quantity =
                  extraItems[found].quantity + (element.quantity % 2);
              }
            }
            if (element.orderDetailUom !== null) {
              restarCupon =
                productosgratis *
                (element.unitPrice * element.orderDetailUom.units);
              setDescuentoItem(restarCupon);
            } else {
              restarCupon = productosgratis * element.unitPrice;
              setDescuentoItem(restarCupon);
            }
            break;
          case 3:
            if (
              element.detailPromotions[0].discountPrice >= element.unitPrice
            ) {
              restarCupon = element.unitPrice * element.quantity;
            } else {
              restarCupon =
                element.detailPromotions[0].discountPrice * element.quantity;
            }

            if (element.orderDetailUom !== null) {
              restarCupon = restarCupon * element.orderDetailUom.units;
            }
            setDescuentoItem(restarCupon);

            break;
          case 4:
            restarCupon =
              ((element.unitPrice *
                element.detailPromotions[0].discountPercent) /
                100) *
              element.quantity;
            setDescuentoItem(restarCupon);

            break;

          default:
            break;
        }
      }

      let extraPrice = 0;
      element.selectedOptions.forEach(
        (selected) => (extraPrice += selected.price)
      );

      if (element.detailPromotions.length !== 0) {
        if (element.orderDetailUom !== null) {
          suma =
            suma +
            (extraPrice + element.unitPrice * element.orderDetailUom.units) *
              element.quantity -
            restarCupon;
          let suma2 =
            element.unitPrice *
              element.orderDetailUom.units *
              element.quantity -
            restarCupon;
          setSumaItem(suma2);
          tmpTotal.push(suma2);
        } else {
          suma =
            suma +
            (extraPrice + element.unitPrice) * element.quantity -
            restarCupon;
          let suma2 =
            (extraPrice + element.unitPrice) * element.quantity - restarCupon;
          setSumaItem(suma2);
          tmpTotal.push(suma2);
        }
      } else if (
        element.detailPromotions.length === 0 ||
        element.detailPromotions[0].type !== 1 ||
        element.detailPromotions[0].type !== 2
      ) {
        if (element.orderDetailUom !== null) {
          suma =
            suma +
            (extraPrice + element.unitPrice * element.orderDetailUom.units) *
              element.quantity;
          let suma2 =
            (extraPrice + element.unitPrice * element.orderDetailUom.units) *
            element.quantity;
          setSumaItem(suma2);
          tmpTotal.push(suma2);
        } else {
          suma = suma + (extraPrice + element.unitPrice) * element.quantity;
          let suma2 = (extraPrice + element.unitPrice) * element.quantity;
          setSumaItem(suma2);
          tmpTotal.push(suma2);
        }
      }

      // suma = suma + element.unitPrice * element.quantity;
    });
    if (orderState.orderPromotions.length !== 0) {
      if (orderState.orderPromotions[0].type === 5) {
        let orderDiscount = orderState.orderPromotions[0].discountPrice;

        setDescuentoOrden(orderDiscount);

        let subConDescuento = suma - orderDiscount;
        if (subConDescuento < 0) {
          subConDescuento = 0;
        }
        setSubtotal(subConDescuento);
      } else if (orderState.orderPromotions[0].type === 6) {
        let orderDiscount =
          (suma * orderState.orderPromotions[0].discountPercent) / 100;
        setDescuentoOrden(orderDiscount);
        setSubtotal(suma - orderDiscount);
      }
    } else {
      setSubtotal(suma);
      setDescuentoOrden(restarCupon);
    }

    //APLICAR DESCUENTOS DMISMO ITEM
    // let desc = 0;

    // if (extraItems.length > 0) {
    //     extraItems.forEach((extraEle) => {
    //         if (extraEle.promoType === 1) {
    //             const freeProd = Math.trunc(extraEle.quantity / 2);
    //             console.log('freeProd', freeProd);
    //             desc = desc + freeProd * extraEle.price;
    //             console.log('des', desc);
    //             // desc = 0;
    //         } else if (extraEle.promoType === 2) {
    //             const freeProd = Math.trunc(extraEle.quantity / 3);
    //             console.log('freeProd', freeProd);
    //             desc = desc + freeProd * extraEle.price;
    //             console.log('des', desc);
    //             // desc = 0;
    //         }
    //     });
    //     console.log('desc', desc);
    //     console.log(suma);
    //     suma = suma - desc;
    //     setSubtotal(suma);
    // }

    setTotalElementos([...tmpTotal]);
    setTipOrder((suma * order.tipPercent) / 100);
    setReloadOrder(false);

    return () => {
      setSubtotal(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadOrder, typeDelivery]);

  // const companyId = getCompanyIdApi();

  function incrementItem(dish) {
    const found = order.details.findIndex(
      (item) => item.detailId === dish.detailId
    );
    orderState.details[found].quantity = dish.quantity + 1;
    setOrderState(orderState);
    setReloadOrder(true);
  }

  function decrementItem(dish) {
    const found = order.details.findIndex(
      (item) => item.detailId === dish.detailId
    );
    orderState.details[found].quantity = dish.quantity - 1;
    setOrderState(orderState);
    setReloadOrder(true);
  }

  function confirm(itemId) {
    setLoadingForm(true);

    if (order.details.length > 1) {
      const foundPosition = order.details.findIndex(
        (item) => item.detailId === itemId
      );
      const found = order.details.find((item) => item.detailId === itemId);
      deleteDish(foundPosition, found);
    } else {
      message.error(
        "No se puede quedar sin artículos, en caso contrario se necesitaría cancelar orden"
      );
      setLoadingForm(false);
    }
  }

  const deleteDish = (itemPosition, item) => {
    let data = {
      CompanyId: orderState.companyId,
      DetailId: item.detailId,
      OrderId: orderState.orderId,
    };

    deleteOrderDetailApi(token, data)
      .then((res) => {
        if (res.message) {
          message.error(
            `Ocurrio un porblema, ${item.itemName} no se pudo eliminar`
          );
          setLoadingForm(false);
          return false;
        } else {
          orderState.details.splice(itemPosition, 1);
          setOrderState(orderState);
          setReloadOrder(true);
          message.success("Eliminado exitosamente");
          setLoadingForm(false);
          return true;
        }
      })
      .catch((err) => {
        return false;
      });
  };

  function cancel(e) {}

  const onFinish = async (values) => {
    if (infoCompany.OrderMinimumPurchase) {
      if (subtotal < infoCompany.OrderMinimumPurchase) {
        notification["error"]({
          message: `Pedido mínimo de $ ${formatMoney(
            infoCompany.OrderMinimumPurchase
          )} `,
        });

        return;
      }
    }
    setLoadingForm(true);
    let totalOrder = 0;
    if (typeDelivery === 2) {
      if (order.delivery === 0) {
        totalOrder = subtotal + costDelivery + tipOrder;
      } else {
        totalOrder = subtotal + costDelivery + tipOrder;

        // totalOrder = subtotal + order.delivery + tipOrder;
      }
    } else {
      totalOrder = subtotal + tipOrder;
    }

    let totalOrderFinal = totalOrder;

    if (values.payType === 1) {
      if (parseFloat(values.changeOf) !== 0) {
        if (parseFloat(values.changeOf) < totalOrderFinal) {
          setLoadingForm(false);

          notification["error"]({
            message: "El cambio debe ser IGUAL o MAYOR que el total.",
          });

          return false;
        }
      } else {
        setLoadingForm(false);

        notification["error"]({
          message: "El cambio debe ser IGUAL o MAYOR que el total.",
        });

        return false;
      }
    }

    orderState.orderType = typeDelivery;
    orderState.payType = values.payType;

    if (values.deliveryCost) {
      orderState.delivery = values.deliveryCost;
    } else {
      orderState.delivery = costDelivery;
    }
    orderState["orderLinesList"] = orderState.details;

    //validar que el admin puede modificar el costo de envio
    // orderState.ignoreDeliveryCost = false;

    //paypal credit card
    if (orderState.payType === 2) {
      orderState.changeOf = 0;
    } else {
      orderState.changeOf = parseFloat(values.changeOf);
    }

    if (typeDelivery === 2) {
      orderState.clientAddress = address;
      if (address === "Sucursal") {
        notification["error"]({
          message: "Debes ingresar una dirección para poder continuar.",
        });
        setLoadingForm(false);
        return;
      }
      // orderState.tableNumber = 0;
      if (orderState.distance !== distance) {
        orderState.distance = distance;
      }
    }

    // if(values.orderType === 3){
    //     orderState.tableNumber = parseInt(values.table);
    //     orderState.clientAddress = "-";

    // }

    orderState.estimatedDeliveryDate = 0;

    const result = await editOrder(token, orderState);

    if (result === undefined) {
      setLoadingForm(false);

      notification["error"]({
        message:
          "Verifique su conexión a internet, si no se resuelve intente más tarde.",
      });

      return false;
    }

    if (result.statusCode === 401) {
      notification["error"]({
        message: "Usuario no autorizado.",
      });

      setTimeout(() => {
        logout();
        window.location.reload();
      }, 1200);

      return false;
    }

    if (result.statusCode === 200) {
      notification["success"]({
        message: "Orden modificada exitosamente",
      });

      if (result.result.status === 1) {
        setReloadOrders(true);
      } else {
        setReloadPendingOrders(true);
      }

      setLoadingForm(false);
      setIsModalVisible(false);
    } else {
      let msg =
        "Ocurrio un problema al modificar una orden, Intentelo otra vez";

      if (
        result?.statusCode === 400 ||
        result?.statusCode === 409 ||
        result?.statusCode === 404
      ) {
        msg = result?.description;
      }
      notification["error"]({
        message: msg,
      });

      setLoadingForm(false);
    }
  };

  const Cancel = () => {
    setIsModalVisible(false);
  };

  const showItemFreePromo = (promo) => {
    let showPromo = {
      show: false,
      text: "",
    };
    if (promo) {
      const { type } = promo;

      if (type === 1 || type === 2) {
        showPromo.show = isValidPromo(promo);

        if (type === 1) {
          showPromo.text = "2x1";
        } else if (type === 2) {
          showPromo.text = "3x2";
        }
      }
    }

    return showPromo;
  };
  const getNewPrice = (promo, originalPrice) => {
    let price = originalPrice;
    if (promo) {
      if (isValidPromo(promo)) {
        if (promo.type === 3) {
          const newPrice = price - promo.discountPrice;
          if (newPrice > 0) {
            price = newPrice;
          } else if (newPrice <= 0) {
            price = 0;
          }
        } else if (promo.type === 4) {
          const pricePorcent = (promo.discountPercent * originalPrice) / 100;
          const newPricePorcent = price - pricePorcent;

          price = newPricePorcent;
        }
      }
    }

    return price;
  };

  const getCountSave = (promo, originalPrice) => {
    let price = originalPrice;
    let save = "";
    if (promo) {
      if (isValidPromo(promo)) {
        if (promo.type === 3) {
          const newPrice = price - promo.discountPrice;
          if (newPrice > 0) {
            price = newPrice;
            save = `Descuento de $ ${formatMoney(promo.discountPrice)}.`;
          } else if (newPrice <= 0) {
            save = `Descuento de $ ${formatMoney(price)}.`;
          }
        } else if (promo.type === 4) {
          // const pricePorcent =
          //     (promo.discountPercent * originalPrice) / 100;
          // const newPricePorcent = price - pricePorcent;

          // price = newPricePorcent;

          save = `Ahorra el ${promo.discountPercent} %.`;
        }
      }
    }

    return save;
  };

  function isValidPromo(promo) {
    let isValid = true;

    // return true;
    return isValid;
  }

  return (
    <Spin tip="Guardando..." spinning={loadingForm} size="large">
      <Form
        {...layout}
        className="edit-order"
        form={form}
        onFinish={onFinish}
        fields={orderForm}
        onFieldsChange={(changedFields, allFields) => {
          if (changedFields[0].name[0] === "payType") {
            setShowChangeOf(changedFields[0].value === 1 ? true : false);
          }

          if (changedFields[0].name[0] === "clientAddress") {
            setAddress(changedFields[0].value);
          }
          // if(changedFields)
          setOrderForm(allFields);
        }}
      >
        <Form.Item
          name="orderType"
          label="Tipo de pedido"
          rules={[
            {
              required: true,
              message: "Por favor seleccione un tipo de pedido",
            },
          ]}
        >
          <Select
            placeholder="Seleccione un tipo de pedido"
            onChange={(e) => setTypeDelivery(e)}
          >
            <Option value={1}>Para llevar</Option>
            <Option value={2}>A domicilio</Option>
            {/* <Option value={3}>Para la mesa</Option> */}
          </Select>
        </Form.Item>

        {typeDelivery === 2 && (
          <>
            <p>Dirección: {address}</p>
            <Button
              type="ghost"
              block
              onClick={() => setIsModalVisibleCost(true)}
            >
              Editar Dirección
            </Button>
          </>
        )}

        {/* {typeDelivery === 3 && <Form.Item
                label="Mesa"
                name="table"
                rules={[
                {
                    required: true,
                    message: 'Por favor ingrese una mesa',
                },
                ]}
            >
                <InputNumber
                    style={{
                        width: '100%',
                    }}
                    placeholder="Mesa" 
                />
            </Form.Item>} */}

        <Form.Item
          className="mt-20"
          name="payType"
          label="Tipo de pago"
          rules={[
            {
              required: true,
              message: "Por favor seleccione un tipo de pedido",
            },
          ]}
        >
          <Select placeholder="Seleccione un tipo de pedido">
            <Option value={1}>Efectivo</Option>
            <Option value={2} disabled>
              Tárjeta de crédito/débito
            </Option>
            {/* <Option value={2}>Terminal a domicilio</Option> */}
          </Select>
        </Form.Item>

        {showChangeOf && (
          <Form.Item
            label="Pagará con"
            name="changeOf"
            rules={[
              {
                required: true,
                message: "Por favor ingrese el precio",
              },
            ]}
          >
            <InputNumber
              min={0}
              style={{
                width: "100%",
              }}
              formatter={(value) =>
                `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              placeholder="Precio"
            />
          </Form.Item>
        )}
        <div
          className={
            orderState.details.length > 3
              ? "basket-form__container-groupsItem"
              : ""
          }
        >
          {orderState.details.map((ele, index) => {
            return (
              <Card className="card-order" key={ele.detailId}>
                <Row>
                  <Col span={24}>
                    {getNewPrice(ele.detailPromotions[0], ele.unitPrice) !==
                      ele.unitPrice && (
                      <p className="banner-discount admin-modal">
                        <TagFilled />
                        {getCountSave(ele.detailPromotions[0], ele.unitPrice)}
                      </p>
                    )}
                    {showItemFreePromo(ele.detailPromotions[0]).show && (
                      <p className="banner-discount item-free">
                        <TagFilled />
                        {showItemFreePromo(ele.detailPromotions[0]).text}
                      </p>
                    )}
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={24}>
                        <h3>{ele.itemName}</h3>
                        {ele.orderDetailUom !== null && (
                          <p className="margin-left">
                            ({ele.orderDetailUom.units.toFixed(2)}{" "}
                            {ele.orderDetailUom.unitTypeName})
                          </p>
                        )}
                      </Col>
                      <Col span={24}>
                        <p>${formatMoney(totalElementos[index])}</p>
                        {/* {ele.orderDetailUom !== null && (

                                                    <p>${formatMoney(sumaItem)}</p>
                                                )}
                                                {ele.orderDetailUom === null && (

                                                    <p>${formatMoney(ele.unitPrice * ele.quantity)}</p>
                                                )} */}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Row className="counter">
                      <Col span={8}>
                        <Button
                          type="text"
                          size="large"
                          disabled={ele.quantity === 1}
                          onClick={() => decrementItem(ele)}
                        >
                          -
                        </Button>
                      </Col>
                      <Col span={8} className="counter__number">
                        {ele.quantity}
                      </Col>
                      <Col span={8}>
                        <Button
                          type="text"
                          size="large"
                          onClick={() => incrementItem(ele)}
                        >
                          +
                        </Button>
                      </Col>
                      <Col span={24} className="text-center">
                        <Popconfirm
                          title={`¿Estás seguro de eliminar el platillo: ${ele.itemName}?`}
                          onConfirm={() => confirm(ele.detailId)}
                          onCancel={cancel}
                          okText="Si"
                          cancelText="No"
                        >
                          <Button icon={<DeleteOutlined />} />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </Col>
                  <div>
                    {ele.selectedOptions.map((option) => (
                      <Row key={option.id}>
                        <Col span={24}>
                          <Text className="section-items">
                            <Text strong>{`${option.sectionName}: `}</Text>
                            <Text>{`${option.name}`}</Text>

                            {option.price && (
                              <Text className="gray margin-left">
                                ${formatMoney(option.price)}
                              </Text>
                            )}
                            {option.price && ele.quantity > 1 && (
                              <Text className="gray margin-left">
                                ($
                                {formatMoney(option.price * ele.quantity)})
                              </Text>
                            )}
                          </Text>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Row>
              </Card>
            );
          })}
        </div>

        {typeDelivery === 2 && orderState.ignoreDeliveryCost && (
          // <Form.Item
          //     label="Costo de envío"
          //     name="deliveryCost"
          //     rules={[
          //         {
          //             required: true,
          //             message: 'Por favor ingrese el costo de envio',
          //         },
          //     ]}
          // >
          //     <InputNumber
          //         min={0}
          //         style={{
          //             width: '100%',
          //         }}
          //         formatter={(value) =>
          //             `$ ${value}`.replace(
          //                 /\B(?=(\d{3})+(?!\d))/g,
          //                 ','
          //             )
          //         }
          //         parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          //         placeholder="Costo de envío"
          //         // onChange={(value) => setCostdelivery(value)}
          //     />
          // </Form.Item>

          <Row gutter={[16, 10]}>
            <Col xs={24} sm={8}>
              <Text type="danger">*</Text>
              <Text style={{ marginLeft: 5 }}>Costo de envío:</Text>
            </Col>
            <Col xs={24} sm={16}>
              <InputNumber
                min={0}
                style={{
                  width: "100%",
                }}
                value={costDelivery}
                formatter={(value) =>
                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder="Costo de envío"
                onChange={(value) => setCostdelivery(value)}
              />
            </Col>
          </Row>
        )}

        {typeDelivery === 2 && !orderState.ignoreDeliveryCost && (
          <h4>Envío: ${formatMoney(costDelivery)}</h4>
        )}

        <h4>Propina: ${formatMoney(tipOrder)}</h4>
        <h4>Descuento: ${formatMoney(descuentoOrden)}</h4>

        {typeDelivery === 2 && (
          <h4>
            Cantidad total a pagar: $
            {formatMoney(subtotal + costDelivery + tipOrder)}
          </h4>
        )}
        {typeDelivery !== 2 && (
          <h4>Cantidad total a pagar: ${formatMoney(subtotal + tipOrder)}</h4>
        )}

        <Divider></Divider>

        <Row>
          <Col span={12}>
            <Button type="ghost" onClick={Cancel}>
              Cancelar
            </Button>
          </Col>
          <Col span={12}>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={infoCompany == null ? true : false}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        isVisible={isModalVisibleCost}
        setIsVisible={setIsModalVisibleCost}
        title={"Costo de Envío"}
        footer={false}
        destroyOnClose={true}
      >
        <DeliveryCostForm
          // address={address}
          infoCompany={infoCompany}
          history={"admin"}
          action={"edit"}
          setCostdelivery={setCostdelivery}
          setAddress={setAddress}
          setDistance={setDistance}
          setIsModalVisibleCost={setIsModalVisibleCost}
          destroyOnClose={true}
          setOrderState={setOrderState}
        />
      </Modal>
    </Spin>
  );
};

export default EditOrderForm;

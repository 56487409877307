import React, { useEffect } from 'react';
import {
  Layout,
  Result,
  Button,
  Row,
  Col,
  Typography,
  notification,
} from 'antd';
import { useParams, withRouter } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import HeaderTop from '../../components/General/HeaderTop';
import { resendEmailConfirmationApi } from '../../api/user';
import { logout } from '../../api/auth';
import { messageError } from '../../utils/general';

import './ValidateEmail.scss';

const ValidateEmail = (props) => {
  const { Content } = Layout;
  const { email } = useParams();
  const companyId = 'TienditaDeLaEsquina';

  useEffect(() => {
    handleResendEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendEmail = async () => {
    const result = await resendEmailConfirmationApi(email, companyId);
    if (result === undefined) {
      const msg = messageError();

      notification['error']({
        message: msg,
      });
      return;
    }
    if (result?.status === 200) {
      notification['success']({
        message: 'Correo enviado exitosamente.',
      });
      logout();
    } else {
      notification['error']({
        message: 'Ocurrió un error, intentelo de nuevo.',
      });
    }
  };

  const logoutUser = () => {
    window.location.href = '/login';
  };

  return (
    <Layout className="confirm-email">
      <Content className="confirm-email__content">
        <HeaderTop
          title={'Correo Electrónico'}
          backIcon={true}
          backUrl={'/login'}
        />

        <div className="confirm-email__container">
          <Result
            status="warning"
            title={<TitleResult />}
            subTitle={<SubTitleResult email={email} />}
            extra={[
              <Button
                key="resend"
                icon={<SendOutlined />}
                type="primary"
                onClick={handleResendEmail}
              >
                Reenviar email
              </Button>,
              <Button
                ghost
                danger
                key="link"
                type="primary"
                onClick={logoutUser}
              >
                Salir
              </Button>,
            ]}
          />
        </div>
      </Content>
    </Layout>
  );
};

function TitleResult() {
  const { Title } = Typography;

  return (
    <Row>
      <Col span={24}>
        <Title level={2}>¡Hola!</Title>
        <Title level={3}>{'Confirma tu correo electrónico por favor.'}</Title>
      </Col>
    </Row>
  );
}

function SubTitleResult({ email }) {
  const { Paragraph, Text } = Typography;

  return (
    <Row>
      <Col span={24}>
        <Paragraph type="secondary">
          Necesitamos validar tu correo electrónico:
        </Paragraph>
        <Paragraph>
          <Text strong>{email}</Text>
        </Paragraph>
        <Paragraph type="secondary">
          {' '}
          Ingresa por favor a tu bandeja de entrada y da click en la liga para
          terminar el proceso.
        </Paragraph>
      </Col>
    </Row>
  );
}

export default withRouter(ValidateEmail);

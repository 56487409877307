import React, { useEffect, useState } from "react";
import {
  Descriptions,
  Button,
  notification,
  Skeleton,
  Divider,
  Typography,
  message,
  Row,
  Col,
  Alert,
} from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import { getDistanceUser, updateUserApi } from "../../../api/user";
import { getAccessTokenApi } from "../../../api/auth";
import { formatMoney, userLogOut } from "../../../utils/general";
import AddressForm from "../../../components/Client/AddressForm";
import useOrder from "../../../hooks/useOrder";
import useUser from "../../../hooks/useUser";
import { citiesMTY } from "../../../utils/validCitiesMTY";
import useCompany from "../../../hooks/useCompany";

import "./DeliveryCostForm.scss";

const DeliveryCostForm = (props) => {
  const {
    setIsModalVisibleCost,
    address = "",
    infoCompany,
    history,
    setCostdelivery,
    setOrderState,
    setAddress,
    action = "check",
    updateAddressClient,
    scheduleCompany,
    setDistance,
    setAddressClient,
    setOrderDelivery,
    setisValidAddress,
    setIsModalVisibleAddress,
    setTypeOrderText,
    setOrderType,
    setOrderDeliveryKM,
  } = props;
  const { person } = useUser();
  const { company } = useCompany();
  const [loading, setLoading] = useState(true);
  const [validAddress, setValidAddress] = useState(false);
  const [addressInput, setAddressInput] = useState(address);
  const [distanceDelivery, setDistanceDelivery] = useState({
    kmTotal: 0,
    kmExtra: 0,
  });
  const [isFarAway, setisFarAway] = useState(false);
  const [showPickUp, setShowPickUp] = useState(false);
  const [ignoredValidation, setIgnoredValidation] = useState(false);
  const { Title, Paragraph } = Typography;
  const [showConfirmAddress, setShowConfirmAddress] = useState(true);
  const [showButtomValidAddress, setShowButtomValidAddress] = useState(false);
  const token = getAccessTokenApi();
  const { orderGeneral, setOrderGeneral, reset } = useOrder();

  useEffect(() => {
    if (address === "" && action === "edit") {
      setValidAddress(false);
      setLoading(false);
      return false;
    }

    if (action === "add-first") {
      setValidAddress(false);
      setLoading(false);
      return false;
    }

    if (address === "" || address === " ") {
      notification["error"]({
        message: "Dirección no valida.",
        description: "No puede estar vacía la dirección",
      });
      setValidAddress(false);
      setLoading(false);
      return false;
    }
    if (action === "edit") {
      setValidAddress(false);
      setLoading(false);
      return false;
    }

    let distanceRestaurant = "";

    if (
      infoCompany.Latitude === 0 ||
      infoCompany.Latitude === null ||
      infoCompany.Longitude === 0 ||
      infoCompany.Longitude === null
    ) {
      distanceRestaurant = "25.7059832,-100.3686972";
    } else {
      distanceRestaurant = `${infoCompany.Latitude},${infoCompany.Longitude}`;
    }

    // let distanceRestaurant = '25.7059832,-100.3686972';
    // let distanceUser = 'Calle Manuel González Rangel 657, San Pedro Garza García, 66236 Monterrey, Nuevo León';
    let addressReceive = address.replace("#", "");

    getDistanceUser(distanceRestaurant, addressReceive).then((res) => {
      if (res === undefined) {
        notification["error"]({
          message: "Ocurrió un Error",
          description: "Intentelo mas tarde",
        });
        setValidAddress(false);
        setLoading(false);
        return false;
      }

      if (res.statusCode === 401) {
        notification["error"]({
          message: "No se permite caracteres",
        });
        setValidAddress(false);
        setLoading(false);
        return false;
      }

      if (
        res.statusCode === 404 ||
        res.statusCode === 400 ||
        res.statusCode === 409
      ) {
        notification["error"]({
          message: "Dirección no valida.",
          description: "Ingrese: Calle No.Exterior, Colonia, Municipio",
        });
        setValidAddress(false);
        setLoading(false);
        return false;
      } else {
        const distanceKM = res.resourceSets[0].resources[0].travelDistance;

        if (distanceKM > infoCompany.DeliverryMaxDistance) {
          if (person.state === "Nuevo León") {
            setIgnoredValidation(false);
          } else {
            setIgnoredValidation(true);
          }
          setisFarAway(true);

          if (infoCompany.ServiceMaxDistance === false) {
            setShowConfirmAddress(false);
          }
        } else {
          if (!showConfirmAddress) {
            setShowConfirmAddress(true);
          }
        }

        const kmExtra = Math.round(distanceKM - infoCompany.DeliveryCostKM);

        let costTotal = 0;

        if (kmExtra > 0) {
          costTotal =
            infoCompany.DeliveryCost +
            infoCompany.DeliveryExtraKmCost * kmExtra;

          setShowPickUp(true);

          if (person.state !== "Nuevo León") {
            setIgnoredValidation(true);
          }
        } else {
          costTotal = infoCompany.DeliveryCost;
          setShowPickUp(false);
        }

        setDistanceDelivery({
          kmTotal: distanceKM,
          kmExtra: kmExtra,
          extraCost: infoCompany.DeliveryExtraKmCost * kmExtra,
          costTotal: costTotal,
          addressApi: addressReceive,
        });

        if (updateAddressClient !== undefined) {
          updateAddressClient(address);
        }
        setLoading(false);
        setValidAddress(true);
      }
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    if (token == null) {
      userLogOut();
    }
  }, [token]);

  const confirmAddress = () => {
    // setContinueOrder(true);
    // setStatusAlert('success');
    // setAddress(addressInput);
    if (history === null) {
      //client side: CHECK ADDRES CARRITO DE COMPRAS
      setIsModalVisibleCost(false);
    } else if (history === "admin") {
      // admin side: EDIT ORDER
      if (ignoredValidation) {
        setCostdelivery(null);
        setOrderState((prevValue) => ({
          ...prevValue,
          ignoreDeliveryCost: true,
        }));
      } else {
        setCostdelivery(distanceDelivery.costTotal);
        setDistance(distanceDelivery.kmTotal);
        setOrderState((prevValue) => ({
          ...prevValue,
          ignoreDeliveryCost: false,
        }));
      }
      setAddress(addressInput);
      setIsModalVisibleCost(false);
    } else if (history === "client-first") {
      //client side: CARRITO DE COMPRAS
      updateAddressClientFirst(addressInput);
      setAddressClient(addressInput);
      setOrderDelivery(distanceDelivery.costTotal);
      setisValidAddress(true);
      setOrderDeliveryKM(distanceDelivery.kmTotal);
      setIsModalVisibleAddress(false);
    } else {
      // client side: HOME-MENU
      if (updateAddressClient !== undefined) {
        updateAddressClient(address);
      }
      setIsModalVisibleCost(false);
      if (orderGeneral.OrderType !== 2) {
        //clear cart
        setOrderGeneral({
          ...orderGeneral,
          IgnoreCostDelivery: ignoredValidation,
          orderLinesList: [],
          Subtotal: 0,
        });
      } else {
        setOrderGeneral({
          ...orderGeneral,
          IgnoreCostDelivery: ignoredValidation,
        });
      }

      history.push({
        pathname: "/menu",
        state: {
          OrderType: 2,
          ClientAddress: address,
          TableNumber: 0,
          DistanceKM: distanceDelivery.kmTotal,
          DeliveryCost: distanceDelivery.costTotal,
          InfoCompany: infoCompany,
          ScheduleCompany: scheduleCompany,
        },
      });
    }
  };

  const changeTypeOrder = () => {
    if (action === "add-first") {
      setAddressClient("Sucursal");
      setTypeOrderText("Para Llevar");
      setOrderType(1);
      setisValidAddress(true);
      setIsModalVisibleAddress(false);

      //hook order
      setOrderGeneral({
        ...orderGeneral,
        OrderType: 1,
        ClientAddress: "",
        delivery: 0,
        DeliveryCost: 0,
      });
    } else {
      setIsModalVisibleCost(false);
      if (orderGeneral.OrderType !== 1) {
        reset();
      }
      history.push({
        pathname: "/menu",
        state: {
          OrderType: 1,
          ClientAddress: address,
          TableNumber: 0,
          DeliveryCost: distanceDelivery.costTotal,
          InfoCompany: infoCompany,
          ScheduleCompany: scheduleCompany,
        },
      });
    }
  };

  const validateAddress = () => {
    let distanceRestaurant = "";

    if (history === "admin" && action === "edit") {
      if (person.state === "Nuevo León") {
        const found = citiesMTY.find((element) => element === person.city);

        if (!found) {
          // El CLIENTE "NO" se encuentra dentro de la ZONA METROPOLITANA de MTY pero si esta en MTY.
          notification["error"]({
            message: "El cliente se encuentra muy lejos del restaurante",
          });
          setIsModalVisibleCost(false);

          return;
        }
      } else {
        if (person.state === company.state) {
          if (person.city !== company.city) {
            notification["error"]({
              message: "El cliente se encuentra muy lejos del restaurante",
            });
            setIsModalVisibleCost(false);
            return;
          }
        } else {
          notification["error"]({
            message: "El cliente se encuentra muy lejos del restaurante",
          });
          setIsModalVisibleCost(false);
          return;
        }
      }
    }

    if (
      infoCompany.Latitude === 0 ||
      infoCompany.Latitude === null ||
      infoCompany.Longitude === 0 ||
      infoCompany.Longitude === null
    ) {
      distanceRestaurant = "25.7059832,-100.3686972";
    } else {
      distanceRestaurant = `${infoCompany.Latitude},${infoCompany.Longitude}`;
    }

    // let distanceRestaurant = '25.7059832,-100.3686972';
    if (addressInput === "") {
      notification["error"]({
        message: "LLene su dirección",
      });
      return false;
    }

    let addressReceive = addressInput.replace("#", "");
    getDistanceUser(distanceRestaurant, addressReceive).then((res) => {
      if (res === undefined) {
        notification["error"]({
          message: "Ocurrió un Error",
          description: "Intentelo mas tarde",
        });
        setValidAddress(false);
        setLoading(false);
        return false;
      }

      if (
        res.statusCode === 404 ||
        res.statusCode === 400 ||
        res.statusCode === 409
      ) {
        notification["error"]({
          message: "Dirección no valida.",
          description: "Ingrese: Calle No.Exterior, Colonia, Municipio",
        });
        setValidAddress(false);
        setLoading(false);
        return false;
      } else {
        const distanceKM = res.resourceSets[0].resources[0].travelDistance;

        if (distanceKM > infoCompany.DeliverryMaxDistance) {
          if (infoCompany.ServiceMaxDistance === false && history === "admin") {
            setValidAddress(false);
            setLoading(false);

            notification["error"]({
              message: "Fuera de rango",
              description: `Exede la distancia permitida (${infoCompany.DeliverryMaxDistance} Km)`,
            });

            return false;
          }

          if (person.state === "Nuevo León") {
            setIgnoredValidation(false);
          } else {
            setIgnoredValidation(true);
          }
          setisFarAway(true);
          // setIgnoredValidation(true);

          if (infoCompany.ServiceMaxDistance === false) {
            setShowConfirmAddress(false);
          }
        } else {
          if (!showConfirmAddress) {
            setShowConfirmAddress(true);
          }
        }

        const kmExtra = Math.round(distanceKM - infoCompany.DeliveryCostKM);

        let costTotal = 0;

        if (kmExtra > 0) {
          costTotal =
            infoCompany.DeliveryCost +
            infoCompany.DeliveryExtraKmCost * kmExtra;
          setShowPickUp(true);
          if (person.state !== "Nuevo León") {
            setIgnoredValidation(true);
          }
        } else {
          costTotal = infoCompany.DeliveryCost;
          setShowPickUp(false);
        }

        setDistanceDelivery({
          kmTotal: distanceKM,
          kmExtra: kmExtra,
          extraCost: infoCompany.DeliveryExtraKmCost * kmExtra,
          costTotal: costTotal,
          addressApi: addressReceive,
        });
        setValidAddress(true);
        setLoading(false);
      }
    });
  };

  const updateAddressClientFirst = async (address) => {
    let data = {
      UserType: 3,
      Address: address,
    };
    const result = await updateUserApi(token, data);

    if (result === undefined) {
      message.error(
        "Ocurrió un error al guardar su dirección, Intentelo mas tarde"
      );
    } else {
      if (result.statusCode === 200) {
        message.success("Se ha actualizado su dirección");
      } else {
        message.error("Ocurrió un error al guardar su dirección");
      }
    }
  };

  return (
    <>
      {loading && <Skeleton active paragraph={{ rows: 4 }}></Skeleton>}
      {!loading && validAddress && (
        <>
          <Row className="basket-form__container-cost-delivery">
            <Col span={24}>
              <Title level={5}>Dirección</Title>
              <Paragraph>{distanceDelivery.addressApi}</Paragraph>
              <Divider></Divider>
              <Title level={5}>Importante</Title>
              <Paragraph>Costo minimo de envío</Paragraph>
              <Paragraph>
                ${formatMoney(infoCompany.DeliveryCost)} (0-
                {infoCompany.DeliveryCostKM} Km)
              </Paragraph>
              {!ignoredValidation && (
                <Paragraph>
                  Km extra ${formatMoney(infoCompany.DeliveryExtraKmCost)}
                </Paragraph>
              )}
            </Col>
          </Row>
          {!ignoredValidation &&
            distanceDelivery.kmTotal < infoCompany.DeliverryMaxDistance && (
              <Descriptions
                bordered
                title="Costo de Envío"
                size="middle"
                column={{
                  xxl: 1,
                  xl: 1,
                  lg: 1,
                  md: 1,
                  sm: 1,
                  xs: 1,
                }}
              >
                <Descriptions.Item label="Km Totales">
                  {Math.round(distanceDelivery.kmTotal)} KM
                </Descriptions.Item>

                {distanceDelivery.kmExtra > 0 && (
                  <Descriptions.Item label="Km Extra">
                    {distanceDelivery.kmExtra} KM
                  </Descriptions.Item>
                )}

                <Descriptions.Item label="Costo mínimo de envío">
                  ${formatMoney(infoCompany.DeliveryCost)}
                </Descriptions.Item>

                {distanceDelivery.kmExtra > 0 && (
                  <Descriptions.Item
                    label={`Costo Extra (1Km - $${infoCompany.DeliveryExtraKmCost})`}
                  >
                    ${formatMoney(distanceDelivery.extraCost)}
                  </Descriptions.Item>
                )}

                <Descriptions.Item label="Costo Total">
                  ${formatMoney(distanceDelivery.costTotal)}
                </Descriptions.Item>
              </Descriptions>
            )}

          {distanceDelivery.kmTotal > infoCompany.DeliverryMaxDistance &&
            !ignoredValidation && (
              <>
                <Paragraph
                  style={{ textAlign: "center" }}
                >{`Excedes los  ${infoCompany.DeliverryMaxDistance} kilometros máximos`}</Paragraph>
                <Descriptions
                  bordered
                  title="Costo de Envío"
                  size="middle"
                  column={{
                    xxl: 1,
                    xl: 1,
                    lg: 1,
                    md: 1,
                    sm: 1,
                    xs: 1,
                  }}
                >
                  <Descriptions.Item label="Costo mínimo de envío">
                    ${formatMoney(infoCompany.DeliveryCost)}
                  </Descriptions.Item>

                  {distanceDelivery.kmExtra > 0 && (
                    <Descriptions.Item
                      label={`Costo Extra (1Km - $${infoCompany.DeliveryExtraKmCost})`}
                    >
                      ${formatMoney(distanceDelivery.extraCost)}
                    </Descriptions.Item>
                  )}

                  <Descriptions.Item label="Costo Total">
                    ${formatMoney(distanceDelivery.costTotal)}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}

          {ignoredValidation && (
            <Alert
              message="Costo de envío"
              description={
                history === "admin"
                  ? "El restaurante deberá proporcionar el costo de envío"
                  : "El costo de envío estará pendiente hasta que el restaurante se contacte con usted..."
              }
              type="info"
              showIcon
            />
          )}

          <div className="cost-delivery__container">
            {isFarAway && (
              <Alert
                message="¡Aviso Importante!"
                description="¿Su dirección es correcta? Parece que se encuentra lejos del restaurante."
                type="warning"
                showIcon
              />
            )}

            {distanceDelivery.kmExtra > 0 && !isFarAway && (
              <Alert
                message="¡Aviso Importante!"
                description="Parece que se encuentra un poco lejos del restaurante."
                type="warning"
                showIcon
              />
            )}
          </div>

          <div className="cost-delivery__container">
            {showConfirmAddress && (
              <Button
                type="primary"
                className="btn-accept"
                block
                disabled={loading}
                onClick={() => confirmAddress()}
              >
                Confirmar Envío
              </Button>
            )}

            {showPickUp && history !== "admin" && history !== null && (
              <Button
                type="ghost"
                danger
                className="btn-change"
                block
                disabled={loading}
                onClick={() => changeTypeOrder()}
              >
                Recoger en el restaurante
              </Button>
            )}
          </div>
        </>
      )}

      {!loading && !validAddress && (
        <div className="cost-delivery__container-address">
          <Divider>Dirección</Divider>
          <AddressForm
            type="admin"
            setAddressInput={setAddressInput}
            setShowButtomValidAddress={setShowButtomValidAddress}
          />

          {showButtomValidAddress && (
            <Paragraph className="text-center">
              {" "}
              <EnvironmentOutlined /> {addressInput}
            </Paragraph>
          )}

          {showButtomValidAddress && (
            <Button
              type="primary"
              className="btn-accept"
              block
              disabled={loading}
              onClick={() => validateAddress()}
            >
              Validar
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default DeliveryCostForm;

import React, { useState } from 'react';
import {
    Form,
    Input,
    Select,
    Tooltip,
    Button,
    Spin,
    Row,
    Col,
    Checkbox,
    notification,
} from 'antd';
import {
    UserOutlined,
    LockOutlined,
    MailOutlined,
    PhoneOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { signUpApi } from '../../../../api/user';
import { validatePassword } from '../../../../utils/formValidation';

import './AddUserForm.scss';
import { createManagerAdminApi } from '../../../../api/managers';
import { getAccessTokenApi } from '../../../../api/auth';

const AddUserForm = ({ setIsVisibleModal, setreloadUsers, idCompanyFather }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const token = getAccessTokenApi();

    const addUser = async (values) => {
        setLoading(true);
        let formData = values;

        formData['phoneNumber'] = formData['phoneNumber'].toString();
        formData['fatherCompanyId'] = idCompanyFather;

        const result = await createManagerAdminApi(token, formData);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
            setLoading(false);
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
                setLoading(false);
            } else {
                //recreo correctamenta
                form.resetFields();
                notification['success']({
                    message: 'Usuario creado exitosamente',
                });
                setLoading(false);

                setIsVisibleModal(false);
                setreloadUsers(true);
            }
        }
    };

    return (
        <div className='add-user-form'>
            <AddForm addUser={addUser} loading={loading} form={form} />
        </div>
    );
};

export default AddUserForm;

function AddForm(props) {
    const { addUser, loading, form } = props;
    const { Option } = Select;

    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    return (
        <Spin tip='Creando cuenta...' spinning={loading}>
            <Form
                form={form}
                className='form-add'
                onFinish={addUser}
                initialValues={{ Active: true }}
            >
                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name='Name'
                            label='Nombre del usuario'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un nombre',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder='Nombre'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name='LastName'
                            label='Apellido del usuario'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un apellido',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder='Apellido'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label='Correo Electrónico'
                            name='Email'
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'El correo electrónico no es válido',
                                },
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un correo electrónico',
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder='Correo electrónico'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name='phoneNumber'
                            label={
                                <span>
                                    Teléfono&nbsp;
                                    <Tooltip title='Tú número telefónico debe contener 10 dígitos'>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder='Teléfono'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label='Rol'
                            name='UserType'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor seleccione un rol',
                                },
                            ]}
                        >
                            <Select placeholder='Selecciona un rol' name='rol'>
                                <Option value={7}>Manager de Sucursales</Option>
                                {/* <Option value={4}>Colaborador</Option> */}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label='Contraseña'
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese una contraseña.',
                                },
                                () => ({
                                    validator(rule, value) {
                                        if (validatePassword(value)) {
                                            return Promise.resolve();
                                        } else if (!validatePassword(value)) {
                                            return Promise.reject(
                                                'La contrase\u00F1a nueva debe de tener  mínimo 6 o más caracteres, por lo menos una may\u00FAscula, una minúscula, un número  y un car\u00E1cter especial.'
                                            );
                                        }
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder='Contraseña'
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name='confirmpassword'
                            label='Repetir contraseña'
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese la contraseña anterior',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Las dos contraseñas ingresadas no son iguales'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder='Repetir contraseña'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    {...tailLayout}
                    name='Active'
                    valuePropName='checked'
                >
                    <Checkbox disabled>UsuarioActivo</Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit' block>
                        Crear usuario
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}

import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Switch,
  DatePicker,
  Upload,
  Image,
  Button,
  TimePicker,
  Tag,
  Row,
  Col,
  message,
  notification,
  Spin,
} from 'antd';
import {
  UploadOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import imageDefault from '../../.././../assets/img/png/camara.ico';
import { addAdvice } from '../../../../api/advice';
import { getAccessTokenApi } from '../../../../api/auth';
import Resizer from 'react-image-file-resizer';

const AddAdviceForm = ({ setIsModalVisible, setReloadAdvices }) => {
  const [image, setImage] = useState({
    imageUrl: '',
  });
  const { imageUrl } = image;

  const [filesList, setFileList] = useState({});
  const [adviceType, setAdviceType] = useState(0);
  const [showPeriod, setShowPeriod] = useState('range-dates');
  const [loadingForm, setLoadingForm] = useState(false);
  const token = getAccessTokenApi();

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const { RangePicker } = DatePicker;
  const format = 'h:mm a';

  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
  };

  const layoutPromoType = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const layoutSwitch = {
    labelCol: { span: 18 },
    wrapperCol: { span: 6 },
  };
  const layoutDatePicker = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 24 },
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Solo puedes subir archivos JPG/PNG!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('La imegen debe ser menos de 2MB!');
    }
    //   return isJpgOrPng && isLt2M;
    return false;
  }

  const handleChange = (info) => {
    let fileList = [...info.fileList];

    if (Array.isArray(fileList) && fileList.length) {
      // 1. Limit the number of uploaded files
      // Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-1);

      let file = fileList[0];

      getBase64(file.originFileObj, (imageUrl) =>
        setImage({
          imageUrl: imageUrl,
        })
      );
    } else {
      setImage({
        imageUrl: null,
      });
    }
    setFileList({ fileList });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList.slice(-1);
  };

  // const changeCheckTime = (e, day) => {
  //     setShowTime({
  //         ...showTime,
  //         [day]: e.target.checked,
  //     });
  // };

  const handleTypeShow = (value) => {
    setShowPeriod(value);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        85,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
    });

  const addPromo = async (values) => {
    setLoadingForm(true);
    if (values.Code) {
      values.Code = values.Code.replace(/\s/g, '');
    }

    if (values.dateLimitRange) {
      values['StartDate'] = values.dateLimitRange[0].unix();
      values['EndDate'] = values.dateLimitRange[1].unix();
    }

    if (values.timeDays) {
      values['TimeStart'] = values.timeDays[0].unix();
      values['TimeEnd'] = values.timeDays[1].unix();
    }

    //tiene imagen
    if (values.upload !== undefined) {
      if (values.upload.length > 0) {
        let image = values.upload[0];

        const isJpgOrPng =
          image.type === 'image/jpeg' ||
          image.type === 'image/png' ||
          image.type === 'image/jpg';

        if (!isJpgOrPng) {
          notification['error']({
            message: '¡Solo puedes subir archivos JPG/PNG/JPEG!',
          });
          setLoadingForm(false);

          return;
        }

        const isLt2M = image.size / 1000 < 250;
        let imageResize = '';
        if (!isLt2M) {
          notification['warning']({
            message:
              'La imagen supera los 250kb, por lo tanto será reducida de tamaño.',
            placement: 'bottomRight',
            duration: 3,
          });
          // message.warning('¡La imagen supera los 250kb, sera reducida de tamaño.');
          imageResize = await resizeFile(image.originFileObj);
        }

        let posData64;

        let posType = image.type.indexOf('/') + 1;
        if (isLt2M) {
          posData64 = image.thumbUrl.indexOf(',') + 1;
          values['Image'] = {
            ImageData: image.thumbUrl.substr(posData64),
            Extension: image.type.substr(posType),
          };
        } else {
          posData64 = imageResize.indexOf(',') + 1;
          values['Image'] = {
            ImageData: imageResize.substr(posData64),
            Extension: image.type.substr(posType),
          };
        }

        // values['Extension'] = image.type.substr(posType);
      }
    }
    delete values.dateLimitRange;
    delete values.upload;
    const result = await addAdvice(token, values);
    if (result === undefined) {
      let messageError =
        'Ocurrio un problema al crear nuevo aviso, Intentelo mas tarde';

      notification['error']({
        message: messageError,
      });

      setLoadingForm(false);
      return;
    }

    if (result.statusCode === 200) {
      form.resetFields();
      setIsModalVisible(false);
      setReloadAdvices(true);
      notification['success']({
        message: 'Promoción agregado exitosamente',
      });
    } else {
      let messageError =
        'Ocurrio un problema al crear nuevo aviso, Intentelo otra vez';

      if (result.statusCode === 400) {
        messageError = result.description;
      }
      if (result.statusCode === 404) {
        messageError = result.description;
      }

      if (result.statusCode === 409) {
        messageError = result.description;
      }

      notification['error']({
        message: messageError,
      });

      setLoadingForm(false);
    }
  };

  const options = [
    { label: 'Lunes', value: 1 },
    { label: 'Martes', value: 2 },
    { label: 'Miércoles', value: 3 },
    { label: 'Jueves', value: 4 },
    { label: 'Viernes', value: 5 },
    { label: 'Sabado', value: 6 },
    { label: 'Domingo', value: 7 },
  ];

  function tagRender(props) {
    const { label, value, closable, onClose } = props;

    let color = '';

    switch (value) {
      case 1:
        color = 'gold';
        break;
      case 2:
        color = 'magenta';
        break;
      case 3:
        color = 'green';
        break;
      case 4:
        color = 'volcano';
        break;
      case 5:
        color = 'blue';
        break;
      case 6:
        color = 'red';
        break;
      case 7:
        color = 'purple';
        break;
      default:
        color = 'cyan';
        break;
    }

    return (
      <Tag
        color={color}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  }

  return (
    <Spin spinning={loadingForm} tip="Guardando...">
      <Form
        {...layout}
        name="add-promo"
        form={form}
        onFinish={addPromo}
        initialValues={{ DurationType: 'range-dates' }}
      >
        <Form.Item
          {...layoutPromoType}
          label="Tipo de Aviso"
          name="noticeType"
          rules={[
            {
              required: true,
              message: 'Por favor seleccione un tipo.',
            },
          ]}
          tooltip="Aviso a Configurar."
        >
          <Select
            placeholder="Seleccione una aviso"
            name="rol"
            onChange={(value) => setAdviceType(value)}
          >
            <Option value={1}>Administrativo</Option>
            <Option value={2}>Fecha Especial</Option>
            <Option value={3}>Clima</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Nombre del aviso"
          name="Name"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese un nombre.',
            },
          ]}
          tooltip="Nombre del aviso."
        >
          <Input placeholder="Nombre" />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="Description"
          rules={[
            {
              required: true,
              message: 'Por favor ingrese una descripción.',
            },
          ]}
          tooltip="Breve descripción del aviso, este concepto se mostrará al cliente."
        >
          <TextArea showCount maxLength={100} />
        </Form.Item>

        <Row>
          <Col xs={24} md={10} style={{ textAlign: 'center' }}>
            <Image width={160} src={imageUrl ? imageUrl : imageDefault} />
          </Col>

          <Col xs={14}>
            <Form.Item
              name="upload"
              label="Imagen"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra="Imagen adjunta"
              tooltip="Imagen del aviso que se mostrará al cliente."
              // hidden={loading}
              dependencies={['ShowInHome']}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value && getFieldValue('ShowInHome')) {
                      return Promise.reject('Debe subir una imágen.');
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Upload
                name="logo"
                beforeUpload={beforeUpload}
                listType="picture"
                multiple={false}
                onChange={handleChange}
                fileList={filesList}
              >
                <Button icon={<UploadOutlined />}>Subir imagen</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Form.Item
              name="toDeliver"
              label="A Domicilio"
              valuePropName="checked"
              {...layoutSwitch}
              tooltip="Tipo de menú en la que se mostrará el aviso."
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item
              name="toGo"
              label="Para Llevar"
              valuePropName="checked"
              {...layoutSwitch}
              tooltip="Tipo de menú en la que se mostrará el aviso."
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Tipo de Fechas"
          name="DurationType"
          rules={[
            {
              required: false,
              message: 'Por favor seleccione un dato.',
            },
          ]}
          tooltip="Se podrá configurar horario específico dentro de la fecha de vigencia."
        >
          <Select placeholder="Seleccione una tipo" onChange={handleTypeShow}>
            <Option value="range-dates">Rango de fecha</Option>
            <Option value="customize-days">Personalizado por dias</Option>
          </Select>
        </Form.Item>

        {showPeriod === 'range-dates' && (
          <Form.Item
            {...layoutDatePicker}
            label="Vigencia"
            name="dateLimitRange"
            rules={[
              {
                required: true,
                message: 'Por favor seleccione una fecha limite.',
              },
            ]}
            tooltip="Rango de fechas de inicio y fin para el aviso."
          >
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        )}

        {showPeriod === 'customize-days' && (
          <>
            <Form.Item
              name="ApplicableDays"
              label="Días aplicables"
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione al menos un día.',
                },
              ]}
              tooltip="Días donde se mostrará el aviso."
            >
              <Select
                mode="multiple"
                showArrow
                tagRender={tagRender}
                style={{ width: '100%' }}
                options={options}
              />
            </Form.Item>
            <Form.Item
              name="timeDays"
              label="Hora de Inicio y Fin "
              rules={[
                {
                  required: true,
                  message: 'Por favor seleccione las horas.',
                },
              ]}
              tooltip="Hora de inicio y fin donde se mostrará el aviso referente a los dias seleccionados."
            >
              <TimePicker.RangePicker format={format} />
            </Form.Item>
          </>
        )}

        {/* HORARIO */}

        <Row>
          <Col xs={8}>
            <Form.Item
              name="isActive"
              label="Activo"
              valuePropName="checked"
              {...layoutSwitch}
              tooltip="Se podrá Activar/Desactivar el aviso aún y que se encuentre dentro de la fecha de vigencia."
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item {...tailLayout} style={{ marginTop: 20 }}>
          <Button type="primary" htmlType="submit" block>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
export default AddAdviceForm;
